import {
  ADD_POWERPANEL_SITES_ID,
  FETCH_API_DATA_FAILURE,
  FETCH_API_DATA_REQUEST,
  FETCH_API_DATA_SUCCESS,
  FETCH_DEVELOPERS_FAILURE,
  FETCH_DEVELOPERS_REQUEST,
  FETCH_DEVELOPERS_SUCCESS,
  FETCH_PERFORMANCE_REQUEST,
  FETCH_WEBSITE_SECURITY_REQUEST,
} from "./actionType";

export const addpowerPanelSitesData = (payload) => ({
  type: ADD_POWERPANEL_SITES_ID,
  payload,
});

//Developer
export const fetchDevelopersRequest = (assetId) => ({
  type: FETCH_DEVELOPERS_REQUEST,
  payload: assetId,
});

export const fetchDevelopersSuccess = (data) => ({
  type: FETCH_DEVELOPERS_SUCCESS,
  payload: data,
});

export const fetchDevelopersFailure = (error) => ({
  type: FETCH_DEVELOPERS_FAILURE,
  payload: error,
});

// fetch api data after add asset
export const fetchApiData = (assetId) => ({
  type: FETCH_API_DATA_REQUEST,
  payload: assetId,
});

export const fetchApiDataSuccess = (data) => ({
  type: FETCH_API_DATA_SUCCESS,
  payload: data,
});

export const fetchApiDataFailure = (error) => ({
  type: FETCH_API_DATA_FAILURE,
  payload: error,
});

export const fetchWebsiteSecurityLoader = (data) => ({
  type: FETCH_WEBSITE_SECURITY_REQUEST,
  payload: data,
});

export const fetcPerformanceLoader = (data) => ({
  type: FETCH_PERFORMANCE_REQUEST,
  payload: data,
});
