import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, CircularProgress, Typography, Box } from "@mui/material";
import axios from "axios";
import common from "../../../Components/Common/common";
import { detect } from "detect-browser";
import { useDispatch } from "react-redux";
import { CRYPTO_TOKEN_SECRET_KEY } from "../../../config";
import CryptoJS from "crypto-js";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { addUserData } from "../../../store/actions";
import { Spinner } from "react-bootstrap";

const SsoEgovCoverSignIn = () => {
  const navigate = useNavigate();
  const browser = detect();
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * Handles the SSO authentication callback.
     * Extracts the token from the URL and validates it.
     */
    const handleCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token"); // SSO token from provider
      const stateUrl = urlParams.get("state"); // Optional return URL
      const apiKey = process.env.REACT_APP_EGOV_API_KEY; // API key for validation

      //   if (!token) {
      //     // Redirect to login if no token is found
      //     navigate("/login");
      //     return;
      //   }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_EGOV_BASE_URL}sso/auth/v1/validate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token, apiKey }),
          }
        );

        const result = await response.json();

        if (result?.data) {
          await handleSsoLogin(result.data.email);
        } else {
          // Token validation failed, redirect to login
          navigate("/login");
        }
      } catch (error) {
        console.error("SSO Validation Error:", error);
        // navigate("/login");
      }
    };

    handleCallback();
  }, [navigate]);

  const handleSsoLogin = async (email) => {
    try {
      let ipAddress = await axios.get(
        `${process.env.REACT_APP_BASE_URL}IpAddress/get`
      );
      const localIp = common.decrypt(ipAddress.data);

      let userData = {
        email: email,
        browserName: browser.name,
        ip: localIp,
      };

      let encryptData = common.encrypt(userData);
      let reqData = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(encryptData),
      };

      const api = await fetch(
        `${process.env.REACT_APP_BASE_URL}user/sso/egov`,
        reqData
      );
      const response = await api.json();
      let data;
      if (response?.data?.data) {
        data = await common.decrypt(response?.data?.data);
      }
      if (response.status === "success") {
        const encryptedRole = CryptoJS.AES.encrypt(
          data?.role,
          CRYPTO_TOKEN_SECRET_KEY
        ).toString();
        const encryptedUserId = CryptoJS.AES.encrypt(
          JSON.stringify(data?.id),
          CRYPTO_TOKEN_SECRET_KEY
        ).toString();
        const encryptedToken = CryptoJS.AES.encrypt(
          response.data.token,
          CRYPTO_TOKEN_SECRET_KEY
        ).toString();
        localStorage.setItem("user_id", encryptedUserId);
        localStorage.setItem("token", encryptedToken);
        // localStorage.setItem("token", response.data.token);
        // localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("role", encryptedRole || data.role);
        ToastMessage.Success("Successfully logged in...");
        navigate("/dashboard");
        dispatch(addUserData(data));
      } else {
        ToastMessage.Error("user does not exist.");
        navigate("/login");
      }
    } catch (error) {
      console.error("SSO Login Error:", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "2px",
          marginTop: "430px",
        }}
      >
        <Spinner />
      </div>
    </Container>
  );
};

export default SsoEgovCoverSignIn;
