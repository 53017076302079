import React, { useState, Suspense, useEffect, useRef } from "react";
import TileBox from "./TileBox";
import TicketingSystem from "../TicketingSystem/TicketingSystem";
import Loader from "../../Components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  TICKETING_SYSTEM,
  ONLINE_ASSETS,
} from "../../Components/Common/ModuleName";
import {
  Box,
  Checkbox,
  Tooltip,
  Drawer,
  FormControlLabel,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import OnlineAssets from "../WebsiteManagement/OnlineAssets";
import {
  fetchDashboardSettingsRequest,
  updateDashboardSettingsRequest,
} from "../../store/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import { toast } from "react-toastify";
import { getUserId } from "../../Api/common";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import {
  Dashboard as DashboardIcon,
  TrendingUp as TrendingUpIcon,
  LocalOffer as LocalOfferIcon,
  Info as InfoIcon,
  Security as SecurityIcon,
  Visibility as VisibilityIcon,
  ListAlt as ListAltIcon,
} from "@mui/icons-material";
import { ToastMessage } from "../../Components/Common/ToastMessage";

const initialComponents = [
  { id: "1", type: "OnlineAssets" },
  { id: "2", type: "TicketingSystem" },
];

const NewDashboard = () => {
  const dispatch = useDispatch();
  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const checkOnlineAsset = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );
  const checkTicketSystem = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );

  const userData = useSelector((state) => state.userDetails.userData);
  const settings = useSelector((state) => state.dashboard.settings);
  const id = getUserId();

  const [components, setComponents] = useState([]);
  const [show, setShow] = useState(false);

  const previousComponentsRef = useRef([]);
  const userId = userData?.id;
  const checkPermission = permissionData?.find(
    (item) => item.module_name === TICKETING_SYSTEM
  );
  const permissioncheck = permissionData?.find(
    (item) => item.module_name === ONLINE_ASSETS
  );

  const [tileBoxTickets, setTileBoxTickets] = useState([]);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const today = new Date().toLocaleDateString(undefined, options);

  useEffect(() => {
    if (userId) {
      dispatch(fetchDashboardSettingsRequest(userId));
    }
  }, [userId, dispatch]);

  const fetchThePreferenceTables = async () => {
    try {
      const response = await axios.get(
        `/dashboard/getDashboardPreference/${id}/${"table"}`
      );
      if (response.status === "success") {
        const data =
          response.data !== null
            ? JSON.parse(response.data?.preferenceTable)
            : null;

        setComponents(data === null ? initialComponents : data);
        previousComponentsRef.current =
          data === null ? initialComponents : data;
      }
    } catch (error) {
      console.error("Error while preferences", error.message);
    }
  };

  const setThePreferenceTables = async (data) => {
    try {
      const response = await axios.post(
        `/dashboard/setDashboardPreference/${id}`,
        {
          preferenceType: "tables",
          preferenceData: JSON.stringify(data),
        }
      );
      if (response.status === "success") {
        toast.success(response.message);
        fetchThePreferenceTables();
      }
    } catch (error) {
      console.error("Internal server error", error.message);
    }
  };

  useEffect(() => {
    fetchThePreferenceTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedComponents = Array.from(components);
    const [movedItem] = reorderedComponents.splice(result.source.index, 1);
    reorderedComponents.splice(result.destination.index, 0, movedItem);

    setComponents(reorderedComponents);

    const previousComponents = previousComponentsRef.current;
    if (
      JSON.stringify(reorderedComponents) !== JSON.stringify(previousComponents)
    ) {
      try {
        await setThePreferenceTables(reorderedComponents);
        previousComponentsRef.current = reorderedComponents;
      } catch (error) {
        console.error("Error while changing the preference", error.message);
      }
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const updatedSettings = { ...settings, [name]: checked };

    dispatch(updateDashboardSettingsRequest(userId, updatedSettings));
    ToastMessage.Success("Dashboard settings updated successfully!");
  };

  const handleClickOpen = () => {
    setShow(true);
  };

  const handleCloseq = () => {
    setShow(false);
  };

  return (
    <Box className="dashboard-page">
      <Box className="page-content">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="16px"
        >
          <Grid item xs={6} alignItems="center">
            <h1 className="page-title" style={{ margin: 0 }}>
              Hello, {userData?.user_name}
            </h1>
            <p
              className="page-subtitle"
              style={{ marginLeft: "10px", margin: 0 }}
            >
              Today is {today}
            </p>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Tooltip title="Dashboard Settings" arrow>
              <Box
                onClick={handleClickOpen}
                style={{
                  backgroundColor: "#333",
                  borderRadius: "50%",
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <DashboardCustomizeIcon style={{ color: "white" }} />
              </Box>
            </Tooltip>
          </Grid>
        </Box>

        <Grid container spacing={5}>
          <Grid item xs={12}>
            <TileBox tileBoxTickets={tileBoxTickets} settings={settings} />
          </Grid>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="ROOT" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {components.length > 1 ? (
                      components.map((component, index) => {
                        return (
                          <Draggable
                            key={component.id}
                            draggableId={component.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  margin: "0 0 30px 0",
                                }}
                              >
                                {component.type === "OnlineAssets" &&
                                  settings.onlineAssetList &&
                                  permissioncheck?.canList != null && (
                                    <OnlineAssets
                                      isDashboardView={true}
                                      paginationDisable={true}
                                      dragHandleProps={provided.dragHandleProps}
                                    />
                                  )}
                                {component.type === "TicketingSystem" &&
                                  settings.ticketList &&
                                  checkPermission?.canList != null && (
                                    <Suspense fallback={<Loader />}>
                                      <TicketingSystem
                                        paginationDisable={true}
                                        isDashboardView={true}
                                        setTileBoxTickets={setTileBoxTickets}
                                        dragHandleProps={
                                          provided.dragHandleProps
                                        }
                                      />
                                    </Suspense>
                                  )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    ) : (
                      <Skeleton
                        className="w-100"
                        variant="rectangular"
                        sx={{
                          background:
                            "linear-gradient(139deg, #CED4DA 0%, #DADEE3 100%)",
                          borderRadius: "12px", // Optional: To match Card border radius if needed
                        }}
                        height={342}
                      />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Box>

      <Drawer anchor="right" open={show} onClose={handleCloseq}>
        <div className="dashboard-setting">
          <h4 className="main-title fw-bold">Dashboard Settings</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginTop: 30,
            }}
          >
            {checkOnlineAsset && checkOnlineAsset?.canList != null && (
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.activeWebsiteCard}
                    onChange={handleChange}
                    name="activeWebsiteCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <DashboardIcon className="svg-icon" />
                    <Typography variant="body1">Active Website Card</Typography>
                  </div>
                }
              />
            )}
            <FormControlLabel
              className="label-grid"
              control={
                <Checkbox
                  checked={settings.avgUserCard}
                  onChange={handleChange}
                  name="avgUserCard"
                  className="cm-input"
                />
              }
              label={
                <div className="label-info">
                  <TrendingUpIcon className="svg-icon" />
                  <Typography variant="body1">Average User Card</Typography>
                </div>
              }
            />
            <FormControlLabel
              className="label-grid"
              control={
                <Checkbox
                  checked={settings.activeTicketCard}
                  onChange={handleChange}
                  name="activeTicketCard"
                  className="cm-input"
                />
              }
              label={
                <div className="label-info">
                  <LocalOfferIcon className="svg-icon" />
                  <Typography variant="body1">Active Ticket Card</Typography>
                </div>
              }
            />
            {checkOnlineAsset && checkOnlineAsset?.canList != null && (
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.hitDetailCard}
                    onChange={handleChange}
                    name="hitDetailCard"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <InfoIcon className="svg-icon" />
                    <Typography variant="body1">Hit Detail Card</Typography>
                  </div>
                }
              />
            )}
            <FormControlLabel
              className="label-grid"
              control={
                <Checkbox
                  checked={settings.complianceCard}
                  onChange={handleChange}
                  name="complianceCard"
                  className="cm-input"
                />
              }
              label={
                <div className="label-info">
                  <SecurityIcon className="svg-icon" />
                  <Typography variant="body1">Compliance Card</Typography>
                </div>
              }
            />
            {checkOnlineAsset && checkOnlineAsset?.canList != null && (
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.onlineAssetList}
                    onChange={handleChange}
                    name="onlineAssetList"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <VisibilityIcon className="svg-icon" />
                    <Typography variant="body1">Online Asset List</Typography>
                  </div>
                }
              />
            )}
            {checkTicketSystem && checkTicketSystem?.canList != null && (
              <FormControlLabel
                className="label-grid"
                control={
                  <Checkbox
                    checked={settings.ticketList}
                    onChange={handleChange}
                    name="ticketList"
                    className="cm-input"
                  />
                }
                label={
                  <div className="label-info">
                    <ListAltIcon className="svg-icon" />
                    <Typography variant="body1">Ticket List</Typography>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default NewDashboard;
