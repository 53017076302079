import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
  Alert,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Html5Qrcode } from "html5-qrcode";
import { Card, Spinner } from "react-bootstrap";

const EgovCertificateVerification = () => {
  const [activeTab, setActiveTab] = useState("home");

  const Home = () => {
    return (
      <>
        <Paper
          elevation={3}
          sx={{ p: 5, textAlign: "center", borderRadius: 3, width: "100%" }}
        >
          <img
            src="https://egov.api.netcluesdemo.com/document/documentFile-1732861710999.png"
            width="50"
            alt="govt-logo"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              width: "100px",
              margin: "0px auto 1.5rem",
            }}
          />
          <Typography variant="h4" color="primary" fontWeight={600}>
            Official Certificate Verification Portal
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, mb: 4 }}>
            Verify the authenticity of certificates issued by authorized
            institutions
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={5}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  minHeight: "240px",
                  cursor: "pointer",
                  transition: "border 0.3s ease",
                  border: "2px solid transparent",
                  "&:hover": { border: "2px solid #1976d2" },
                }}
                onClick={() => setActiveTab("VerifyByNumber")}
              >
                <AssignmentIcon sx={{ fontSize: 50, color: "gray" }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Verify by Number
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Enter the certificate number to verify its authenticity
                </Typography>
                <Button variant="contained" sx={{ mt: 4 }}>
                  Select
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  minHeight: "240px",
                  cursor: "pointer",
                  transition: "border 0.3s ease",
                  border: "2px solid transparent",
                  "&:hover": { border: "2px solid #1976d2" },
                }}
                onClick={() => setActiveTab("scanQrCode")}
              >
                <QrCodeScannerIcon sx={{ fontSize: 50, color: "gray" }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Scan QR Code
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Scan the QR code printed on the certificate for instant
                  verification
                </Typography>
                <Button variant="contained" sx={{ mt: 2 }}>
                  Select
                </Button>
              </Paper>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ marginTop: "50px" }}
          >
            All certificates can be verified online to check their validity
          </Typography>
        </Paper>
      </>
    );
  };

  const VerifyByNumber = () => {
    const [error, setError] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);

    const verifyCertificate = async () => {
      setSubmitLoading(true);
      try {
        // API authentication credentials - store these securely in production
        const apiKey = process.env.REACT_APP_EGOV_API_KEY;
        const clientId = process.env.REACT_APP_EGOV_CLIENT_ID;
        const certificateNumber =
          document.getElementById("certificateNumber").value;

        if (!certificateNumber) {
          setError("Please enter a certificate number");

          setTimeout(() => {
            setError(null);
          }, 3000);
          return;
        }

        // Prepare request data in required format
        const data = {
          certificateNumber,
          apiKey,
          clientId,
        };

        // Send request to the eGov verification API
        const response = await fetch(
          "https://egov.api.netcluesdemo.com/api/v1/certificate/verify",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );

        const result = await response.json();

        if (result.success) {
          // Display verification result
          displayCertificateDetails(result);
        } else {
          setError(
            "Certificate verification failed: " +
              (result?.message || "Unknown error")
          );

          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      } catch (error) {
        console.error("Verification Error:", error);
        alert("Verification failed. Please try again.");
      } finally {
        setSubmitLoading(false);
      }
    };

    const displayCertificateDetails = (result) => {
      const resultElement = document.getElementById("verificationResult");

      let statusClass =
        result.success && !result.expire ? "alert-success" : "alert-danger";
      let expirationNotice = result.expire
        ? '<p><strong>Status:</strong> <span class="text-danger">Expired</span></p>'
        : "";

      resultElement.innerHTML = `
          <div class="alert ${statusClass}">
            <h4>Certificate Verification Result</h4>
            <p><strong>Message:</strong> ${result.message}</p>
            ${expirationNotice}
            <p><strong>Document:</strong> <a href="${result.data}" target="_blank"  style="color: #1976d2; text-decoration: none; font-weight: bold;">View Certificate</a></p>
          </div>
        `;
    };

    return (
      <Paper
        elevation={3}
        sx={{ p: 5, textAlign: "center", borderRadius: 3, width: "100%" }}
      >
        <Box display="flex" mb={2}>
          <ArrowBackIcon
            sx={{ cursor: "pointer", mr: 2 }}
            onClick={() => setActiveTab("home")}
          />
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ textTransform: "uppercase" }}
          >
            Certificate Number Verification
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Enter your certificate number to verify its authenticity and validity
          status.
        </Typography>
        {error && (
          <Box
            className="alert alert-borderless alert-danger text-center mb-2 mx-2 mt-4"
            role="alert"
          >
            {error}
          </Box>
        )}

        <TextField
          fullWidth
          variant="outlined"
          id="certificateNumber"
          label="Certificate Number"
          placeholder="Enter certificate number"
          sx={{ marginTop: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2, backgroundColor: "#5F9FD6" }}
          onClick={() => {
            verifyCertificate();
          }}
        >
          {submitLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: "5px" }}
              />{" "}
              Please wait...
            </>
          ) : (
            "Verify Certificate"
          )}
        </Button>
        <div style={{ marginTop: "30px" }} id="verificationResult"></div>
        <Box
          sx={{
            marginTop: 3,
            padding: 2,
            backgroundColor: "#F5F5F5",
            borderRadius: 2,
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            How to find your certificate number
          </Typography>
          <Typography variant="body2">
            • Located at the top right corner of your certificate
          </Typography>
          <Typography variant="body2">
            • Format: XXX-XXXXX-XXX (12 alphanumeric characters)
          </Typography>
          <Typography variant="body2">
            • Case sensitive - enter exactly as shown
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={() => setActiveTab("home")}
          >
            Start Over
          </Button>
        </Box>
      </Paper>
    );
  };

  const ScanQrCode = () => {
    const [scanResult, setScanResult] = useState(null);
    const [verificationResult, setVerificationResult] = useState(null);
    const [error, setError] = useState(null);
    const [scannerStarted, setScannerStarted] = useState(false);
    let html5QrCode;

    const initQRScanner = () => {
      html5QrCode = new Html5Qrcode("qr-reader");

      html5QrCode.start(
        { facingMode: "environment" }, // Use rear camera
        { fps: 10, qrbox: 250 },
        async (decodedText) => {
          await html5QrCode.stop();
          setError(null);
          setScanResult(decodedText);
          verifyDocumentByQR(decodedText);
        }
      );

      setScannerStarted(true);
    };

    const verifyDocumentByQR = async (result) => {
      try {
        if (!result) return;

        const urlObj = new URL(result);
        const params = new URLSearchParams(urlObj.search);

        // Attach API credentials
        params.set("apiKey", process.env.REACT_APP_EGOV_API_KEY);
        params.set("clientId", process.env.REACT_APP_EGOV_CLIENT_ID);

        urlObj.search = params.toString();
        const verificationUrl = urlObj.toString();

        const response = await fetch(verificationUrl);
        const responseData = await response.json();

        if (responseData?.success) {
          setVerificationResult(responseData);
          setError(null);
        } else {
          setError(responseData?.message || "Verification failed.");
        }
      } catch (error) {
        console.error("QR Verification Error:", error);
        setError("Verification failed. Please try again.");
      }
    };

    return (
      <Paper
        elevation={3}
        sx={{ p: 5, textAlign: "center", borderRadius: 3, width: "100%" }}
      >
        <Box display="flex" mb={2}>
          <ArrowBackIcon
            sx={{ cursor: "pointer", mr: 2 }}
            onClick={() => setActiveTab("home")}
          />
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ textTransform: "uppercase" }}
          >
            Certificate QR Verification
          </Typography>
        </Box>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Scan the QR code on your certificate for instant verification.
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}

        {!scanResult && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px dashed gray",
                p: 3,
                borderRadius: 2,
              }}
            >
              <div
                id="qr-reader"
                style={{ width: "100%", maxWidth: "500px" }}
              ></div>

              <Typography variant="body2" color="textSecondary">
                Please scan a QR code
              </Typography>

              <Button
                variant="contained"
                color="primary"
                startIcon={<QrCodeScannerIcon />}
                onClick={initQRScanner}
                sx={{ mt: 2 }}
              >
                Start Scanning
              </Button>
            </Box>
          </>
        )}

        {verificationResult && (
          <Box
            id="verificationResult"
            sx={{
              mt: 3,
              p: 2,
              border: "1px solid gray",
              borderRadius: 2,
              textAlign: "left",
            }}
          >
            <Typography variant="h6">
              {verificationResult.success && "Certificate Verification Result"}
            </Typography>
            <Typography variant="body2">
              Message: {verificationResult.message}
            </Typography>
            {verificationResult.expire && (
              <Typography variant="body2" color="red">
                Status: Expired
              </Typography>
            )}
            <Typography variant="body2">
              Document:{" "}
              <a
                href={verificationResult.data}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#1976d2",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                View Certificate
              </a>
            </Typography>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 3 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setActiveTab("home")}
          >
            Start Over
          </Button>
          {scanResult && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setScanResult(null);
                setVerificationResult(null);
                setScannerStarted(false); // Restart QR scanner
              }}
            >
              Scan Again
            </Button>
          )}
        </Box>
      </Paper>
    );
  };

  return (
    <>
      <Card className="table-main-card">
        {activeTab === "home" && <Home />}
        {activeTab === "VerifyByNumber" && <VerifyByNumber />}
        {activeTab === "scanQrCode" && <ScanQrCode />}
      </Card>
    </>
  );
};

export default EgovCertificateVerification;
