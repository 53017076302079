import { PDFDownloadLink } from "@react-pdf/renderer";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";
import {
  fetchOnlineAssetsReq,
  fetchSecurityHistoryReq,
  fetchSecurityScanTimeReq,
  postScheduleDataReq,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import {
  fetchOnlineAssetsRes,
  fetchSecurityHistoryRes,
  fetchSecurityScanTimeRes,
  postScheduleDataRes,
} from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsRes";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../../Components/Common/FormatDateTIme";
import Flatpickr from "react-flatpickr";
import { Form, ModalBody, Spinner } from "reactstrap";
import DataTable from "react-data-table-component";
import DesktopWindowsRoundedIcon from "@mui/icons-material/DesktopWindowsRounded";
import {
  CalenderClockIcon,
  InboxInIcon,
} from "../../../Components/Common/SvgIcons";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import Radio from "@mui/material/Radio";

import CONSTANT from "./Constant";
import PDFDocument from "../../NewDashboard/DashboardAnalyticTab/WebsiteReport/PDFDocument";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import common from "../../../Components/Common/common";
import {
  getPackagesServices,
  getUserPackageLimit,
} from "../../../helpers/Help/CommomApi";
import Loader from "../../../Components/Common/Loader";
import RunScan from "../../NewDashboard/DashboardAnalyticTab/common/runScan";
import { getAuthToken, getUserId } from "../../../Api/common";
const MyListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const WebSecurityDetailPage = (props) => {
  const {
    SummaryCardOneComponent,
    SummaryCardTwoComponent,
    SecurityTestCardComponent,
    WebSoftwareSecurityTestComponent,
    GDPRComplianceTestComponent,
    PCIDSSComplianceTestComponent,
    ContentSecurityPolicyTestComponent,
    CookiePrivacyAndSecurityTestComponent,
    HTTPHeaderSecurityComponent,
  } = CONSTANT;

  const token = getAuthToken();
  const params = useParams();
  const choicesRef = useRef(null);
  const cardRefs = useRef([]); // Array of refs for detailed sections
  const navigate = useNavigate();
  const userId = getUserId();
  const userData = useSelector((state) => state.userDetails.userData);
  let daysdata = "30";
  let analyticDaysData = "1";

  const [immuniWeb, setImmuniWeb] = useState([]);

  const [iwTest, setTest] = useState([]);
  const [userName, setUserName] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [securityHistory, setSecurityHistory] = useState([]);
  const [securityHistoryCount, setSecurityHistoryCount] = useState(null);
  const [securityHistoryScanTime, setSecurityHistoryScanTime] = useState([]);
  const [show, setShow] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [error, setError] = useState("");
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [scheduleErrorMessage, setScheduleErrorMessage] = useState(null);
  const [scanType, setScanType] = useState("false");
  const [assetsData, setAssetsData] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [myPackage, setMyPackage] = useState([]);
  const [myUsersLimit, setMyUsersLimit] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const MemoizedMyHeaders = useMemo(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);
    return headers;
  }, [token]);

  const MemoizedUrlencoded = useMemo(() => {
    const URLSearch = {};

    if (emailData?.length > 0) {
      URLSearch.sendResponse = true;
      URLSearch.email = emailData;
    }
    if (daysdata !== undefined) {
      URLSearch.uptimeDays = daysdata.toString();
    }
    if (analyticDaysData !== undefined) {
      URLSearch.days = analyticDaysData.toString();
    }

    URLSearch.assets_id = params.id;
    URLSearch.users_id = userId;

    return URLSearch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData, daysdata, analyticDaysData, params.id]);

  const memoizedRequestOptions = useMemo(() => {
    return {
      method: "POST",
      headers: MemoizedMyHeaders,
      body: JSON.stringify(MemoizedUrlencoded),
      redirect: "follow",
    };
  }, [MemoizedMyHeaders, MemoizedUrlencoded]);

  const fetchOnlineAssets = useCallback(async () => {
    try {
      const { url, userData } = fetchOnlineAssetsReq({ params, token });

      let result = await fetch(url, userData);
      let response = await result.json();

      fetchOnlineAssetsRes({
        response,
        setAssetsData,
        navigate,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, navigate, token]);

  useEffect(() => {
    fetchOnlineAssets();
  }, [fetchOnlineAssets]);

  const fetchImmuniWeb = useCallback(async () => {
    try {
      // setDataLoading(true);
      let immuni = await fetch(
        API_ROUTES.GET_IMMUNI_WEB_V2_DATA,
        memoizedRequestOptions
      );
      let data1 = await immuni.json();
      const responseData = common.decrypt(data1.data);
      data1.data = responseData;
      const responseMessage = common.decrypt(data1.message);
      data1.message = responseMessage;

      if (data1.status === "success") {
        setUserName(data1.userName);
        setImmuniWeb(data1.data);
        setTest(data1.data.updatedAt);
        setDataLoading(false);
      }
      if (data1.status === "fail") {
        setDataLoading(false);
        return null;
      }
    } catch (error) {
      console.error(error);
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  }, [memoizedRequestOptions]);

  const fetchSecurityHistory = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityHistoryReq({
        params,
        userId,
        token,
      });

      let schedule = await fetch(url, userData);
      let response = await schedule.json();

      fetchSecurityHistoryRes({
        response,
        setSecurityHistory,
        setSecurityHistoryCount,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const fetchSecurityScanTime = useCallback(async () => {
    try {
      const { url, userData } = fetchSecurityScanTimeReq({
        params,
        userId,
        token,
      });

      let scheduleSecurityTime = await fetch(url, userData);
      let response = await scheduleSecurityTime.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      fetchSecurityScanTimeRes({
        response,
        setSecurityHistoryScanTime,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, userId, token]);

  const postScheduleData = async (recurringScan) => {
    try {
      const { url, userData } = postScheduleDataReq({
        token,
        userName,
        userId,
        params,
        scheduleDate,
        recurringScan,
      });

      let result = await fetch(url, userData);
      let response = await result.json();

      postScheduleDataRes({
        response,
        ToastMessage,
        fetchSecurityHistory,
        fetchSecurityScanTime,
        setScheduleDate,
        setShowFirstModal,
        setScanType,
      });
      // fetchUserPackageLimit(userId);
      // fetchPackageServiceDataByUserId(userId);
    } catch (error) {
      console.error(error);
    }
  };

  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);

  // send reports
  const handleShowInput = () => setShow(true);

  const handleCloseInput = () => {
    setError("");
    setShow(false);
  };

  const handleSaveChangesClick = useCallback(() => {
    const selectedItems = choicesRef.current.getValue(true); // Get all added email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (selectedItems.length === 0) {
      setError("Please enter at least one email address.");
    } else if (selectedItems.some((email) => !emailRegex.test(email))) {
      setError("One or more email addresses are invalid.");
    } else {
      setError("");
      setEmailData(selectedItems); // Save valid email data
      handleCloseInput();
      ToastMessage.Success("Report sent successfully.");
    }
  }, [choicesRef, setEmailData, handleCloseInput]);

  useEffect(() => {
    if (show) {
      const element = document.getElementById("choices-email-input");
      if (element) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const choices = new Choices(element, {
          silent: false,
          items: [],
          choices: [],
          renderChoiceLimit: -1,
          maxItemCount: 1, // Set maximum emails to 5 (change as needed)
          closeDropdownOnSelect: true,
          singleModeForMultiSelect: false,
          addChoices: false,
          addItems: true,
          addItemFilter: (value) => {
            // Check if value is valid and not empty
            return !!value && value.trim() !== "" && emailRegex.test(value);
          },
          removeItems: true,
          removeItemButton: true,
          duplicateItemsAllowed: false, // Prevent duplicate items
          delimiter: ",",
          paste: true,
          searchEnabled: true,
          searchChoices: true,
          searchFloor: 1,
          searchResultLimit: 4,
          searchFields: ["label", "value"],
          position: "auto",
          resetScrollPosition: true,
          shouldSort: true,
          shouldSortItems: false,
          placeholder: true,
          placeholderValue: "Enter email address",
          addItemText: (value) => {
            // Custom message for adding an item
            if (!emailRegex.test(value)) {
              return "Please enter a valid email address.";
            }
            return `Press Enter to add ${value}`;
          },
          uniqueItemText: "This email address has already been added.", // Prevent duplicates
          maxItemText: (maxItemCount) => {
            return `Only ${maxItemCount} email addresses can be added.`;
          },
          noChoicesText: "No email addresses available to select.",
          noResultsText: "No results found.",
          classNames: {
            containerOuter: ["choices"],
            containerInner: ["choices__inner"],
            input: ["choices__input"],
            list: ["choices__list"],
            item: ["choices__item"],
          },
          fuseOptions: {
            includeScore: true,
          },
        });

        choicesRef.current = choices;
      }
    }

    // Cleanup Choices instance when modal closes
    return () => {
      if (choicesRef.current) {
        choicesRef.current.destroy();
        choicesRef.current = null;
      }
    };
  }, [show]);

  const handleFirstModalClose = () => {
    setShowFirstModal(false);
  };

  const handleScanTypeChange = (event) => {
    setScanType(event.target.value);
  };

  const toggleShow = () => {
    setScheduleDate("");
    setShowFirstModal(!showFirstModal);
  };

  const currentDateTime = new Date();

  let closestTimeDifference = Infinity;

  let nearestDate = null;
  let nearestData = null;

  securityHistory.forEach((item) => {
    const createdAtDateTime = new Date(item.createdAt);
    const timeDifference = Math.abs(currentDateTime - createdAtDateTime);

    if (timeDifference < closestTimeDifference) {
      closestTimeDifference = timeDifference;
    }
  });

  securityHistoryScanTime.forEach((dateTimeString) => {
    const date = new Date(dateTimeString.schedule_date_time);

    if (date > currentDateTime && (!nearestDate || date < nearestDate)) {
      nearestDate = date;
      nearestData = dateTimeString;
    }
  });

  useEffect(() => {
    fetchImmuniWeb();
    fetchSecurityHistory();
    fetchSecurityScanTime();
  }, [
    fetchSecurityHistory,
    fetchImmuniWeb,
    fetchSecurityScanTime,
    daysdata,
    analyticDaysData,
    iwTest,
  ]);

  const getDefaultMinTime = () => {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 15); // Add 15 minutes
    return date.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const initialMinTime = getDefaultMinTime();

  const handleDate = (selectedDates) => {
    setScheduleDate(selectedDates[0]);
  };

  const summaryData = immuniWeb?.summary ? JSON.parse(immuniWeb.summary) : null;
  const webServerSecurityTest = immuniWeb?.web_server_security_test
    ? JSON.parse(immuniWeb.web_server_security_test)
    : null;

  const webSoftwareSecurityTest = immuniWeb?.web_software_security_test
    ? JSON.parse(immuniWeb.web_software_security_test)
    : null;

  const gdprComplianceTest = immuniWeb?.gdpr_compliance
    ? JSON.parse(immuniWeb.gdpr_compliance)
    : null;

  const pcidssComplianceTest = immuniWeb?.pci_dss_compliance
    ? JSON.parse(immuniWeb.pci_dss_compliance)
    : null;

  const httpHeaderSecurityData = immuniWeb?.http_header_security_data
    ? JSON.parse(immuniWeb.http_header_security_data)
    : null;

  const contentSecurityPolicyTest = immuniWeb?.content_security_policy_test
    ? JSON.parse(immuniWeb.content_security_policy_test)
    : null;

  const cookiesPrivacySecurityTest = immuniWeb?.cookies_privacy_security_test
    ? JSON.parse(immuniWeb.cookies_privacy_security_test)
    : null;

  let immuni = immuniWeb?.length === 1 ? immuniWeb : undefined;
  const testedLength = securityHistoryCount || 0;
  const testCount = testedLength ?? "-";

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;
  const time = common.formateTime(timeZone);

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        formatDateTime(
          row?.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        ).timeZone.formattedDateFinal || "-",
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        formatDateTime(
          row?.schedule_date_time,
          dateFormat,
          timeZone,
          timeFormat
        ).timeZone.formattedTimed || "-",
      sortable: true,
    },
    {
      name: "Done By",
      selector: (row) => row?.tested_by,
      sortable: true,
    },
    {
      name: "Completed Date",
      selector: (row) =>
        row.completed_date_time
          ? formatDateTime(
              row?.completed_date_time,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedDateFinal
          : "-",
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) =>
        row.completed_date_time
          ? formatDateTime(
              row?.completed_date_time,
              dateFormat,
              timeZone,
              timeFormat
            ).timeZone.formattedTimed
          : "-",
      sortable: true,
    },
    {
      name: "Report",
      cell: (row) => (
        <a href="*">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-file-text icon-sm"
          >
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
        </a>
      ),
    },
  ];

  const ReportData = [
    {
      id: 1,
      icon: <SendOutlinedIcon />,
      title: "Send Report",
      onclick: handleShowInput,
    },
    {
      id: 2,
      icon: <InboxInIcon />,
      title: "Download Report",
    },
    {
      id: 3,
      icon: <CalenderClockIcon />,
      title: "Schedule Security Scan",
      onclick: toggleShow,
    },
  ];

  const ListCardData = [
    {
      id: 1,
      icon: <CalenderClockIcon />,
      title: "Security Tested On",
      time: (
        <span>
          {dataLoading ? ( // Check if data is still loading
            <Skeleton variant="text" width={150} height={35} />
          ) : iwTest?.length > 0 ? ( // If iwTest is available
            <>
              {formatDateTime(iwTest, dateFormat, timeZone, timeFormat).timeZone
                .formattedDateFinal || "-"}{" "}
              {formatDateTime(iwTest, dateFormat, timeZone, timeFormat).timeZone
                .formattedTimed || "-"}
            </>
          ) : immuni?.updatedAt ? ( // If iwTest is not available but immuni data is available
            <>
              {formatDateTime(
                immuni.updatedAt,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedDateFinal || "-"}{" "}
              {formatDateTime(
                immuni.updatedAt,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedTimed || "-"}
            </>
          ) : (
            <>
              <div>
                <h5>No Data Available</h5>
                <p>Please check back later for updated security test data.</p>
              </div>
            </>
          )}
        </span>
      ),
    },
    {
      id: 2,
      icon: <UpdateRoundedIcon />,
      title: "Next Security Test on",
      time: (
        <span>
          {dataLoading ? (
            <Skeleton variant="text" width={150} height={35} />
          ) : nearestData ? (
            <>
              {formatDateTime(
                nearestData?.schedule_date_time,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedDateFinal || "-"}{" "}
              {formatDateTime(
                nearestData?.schedule_date_time,
                dateFormat,
                timeZone,
                timeFormat
              ).timeZone.formattedTimed || "-"}
            </>
          ) : (
            // Display fallback message if no date is scheduled
            <div>
              <h5>Date Not Scheduled</h5>
            </div>
          )}
        </span>
      ),
    },
    {
      id: 3,
      icon: <Person2OutlinedIcon />,
      title: "Scan By",
      time: (
        <span>
          {userName !== undefined ? (
            <>
              {dataLoading ? (
                <Skeleton variant="text" width={150} height={35} />
              ) : (
                `${userName}`
              )}
            </>
          ) : (
            " "
          )}
        </span>
      ),
    },
  ];

  const renderListCard = ListCardData.map((data) => {
    return (
      <Col xs={12} key={data.id}>
        <Card className="list-card">
          {data.icon}
          <Box>
            <div className="title">{data.title}</div>
            <div className="subtitle">{data.time}</div>
          </Box>
        </Card>
      </Col>
    );
  });

  // const fetchPackageServiceDataByUserId = async (userId) => {
  //   try {
  //     const response = await getPackagesServices(userId);
  //     const responseData = common.decrypt(response.data);
  //     setMyPackage(responseData);
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  // const fetchUserPackageLimit = async (userId) => {
  //   try {
  //     const response = await getUserPackageLimit(userId);
  //     const responseData = common.decrypt(response.data);
  //     setMyUsersLimit(responseData);
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchUserPackageLimit(userId);
  //   fetchPackageServiceDataByUserId(userId);
  // }, [userId]);

  const securityScan = myUsersLimit?.security_scan
    ? myUsersLimit?.security_scan
    : null;

  const securityScanLimit = myUsersLimit?.security_scan_report_limit
    ? myUsersLimit?.security_scan_report_limit
    : null;

  const securityScanLimitUnit = myUsersLimit?.security_scan_report_limit_unit
    ? myUsersLimit?.security_scan_report_limit_unit
    : null;

  const scheduleSecurityScan = myUsersLimit?.schedule_security_scan
    ? myUsersLimit?.schedule_security_scan
    : null;

  const securityPackageData = myPackage[0]?.packageServiceData?.find(
    (service) => service.slug === "security_scan_report_limit"
  );

  const secuirtyPackageLimitValue = securityPackageData
    ? securityPackageData.value
    : null;

  const isLimitReached =
    userData?.role !== "Super Admin" &&
    secuirtyPackageLimitValue !== null &&
    securityScanLimit >= secuirtyPackageLimitValue;

  const isPackageExpired =
    userData?.role !== "Super Admin" && myUsersLimit?.isPackageExpired;

  const isPackageInactive =
    userData?.role !== "Super Admin" && myUsersLimit?.packageInactive;

  const isUserStatusPaused =
    userData?.role !== "Super Admin" &&
    myUsersLimit?.isUserStatusCheck === false;

  const rensderReportCard = ReportData?.map((data) => {
    const isDisabled =
      userData?.role !== "Super Admin" &&
      data?.id === 3 &&
      scheduleSecurityScan === "false";

    const isLimitReachedCheck = data?.id === 3 && isLimitReached;

    // Set tooltip message based on priority
    const tooltipMessage = isPackageInactive
      ? common.PACKAGEINACTIVE
      : isUserStatusPaused
      ? common.ADMINMESSAGE
      : isLimitReachedCheck
      ? common.SCHEDULESECURITYLIMITEMESSAGE
      : isPackageExpired
      ? common.SCHEDULESECURITYEXPIREMESSAGE
      : isDisabled
      ? common.SCHEDULESECURITYNOTAVAILABLE
      : "";

    const isTooltipVisible = !!tooltipMessage;
    const isClickable = !(
      isDisabled ||
      isLimitReachedCheck ||
      isUserStatusPaused ||
      isPackageExpired ||
      isPackageInactive
    );

    return (
      <Col xs={12} key={data?.id}>
        <Card className="report-card">
          {data?.id === 3 ? (
            <OverlayTrigger
              placement="top"
              overlay={
                isTooltipVisible ? (
                  <Tooltip id={`${data.id}-tooltip`}>{tooltipMessage}</Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <Card.Body
                onClick={isClickable ? data?.onclick : undefined}
                style={{
                  cursor: isClickable ? "pointer" : "not-allowed",
                  opacity: isClickable ? 1 : 0.6,
                }}
                className="d-flex align-items-center gap-2"
              >
                {data.icon}
                <p className="title mb-0">{data.title}</p>
              </Card.Body>
            </OverlayTrigger>
          ) : data.id === 2 ? (
            <PDFDownloadLink
              document={
                <PDFDocument
                  onlineAssetData={assetsData}
                  immuniWeb={immuniWeb}
                  userName={userName}
                  testCount={testCount}
                />
              }
              fileName="Website Security Report.pdf"
            >
              <Card.Body className="d-flex align-items-center gap-2">
                {data.icon}
                <p className="title mb-0">{data.title}</p>
              </Card.Body>
            </PDFDownloadLink>
          ) : (
            <Card.Body
              onClick={data.onclick}
              className="d-flex align-items-center gap-2"
            >
              {data.icon}
              <p className="title mb-0">{data.title}</p>
            </Card.Body>
          )}
        </Card>
      </Col>
    );
  });

  const getEndOfMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // Last date of the month at 23:59
  };

  const getEndOfDay = () => {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ); // Today at 23:59
  };

  const getMinTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`; // Current time (e.g., 13:45)
  };

  const dynamicOptions =
    userData?.role === "Super Admin"
      ? {} // No restrictions for super admin
      : {
          minDate: new Date(), // Current date time as minimum
          maxDate:
            securityScanLimitUnit === "Month" ? getEndOfMonth() : getEndOfDay(), // End of month or day
          minTime: initialMinTime, // : "00:00", // Current time for today
          maxTime: "23:59", // Always 23:59 as max time
        };

  const handleRunTestApi = async (paramsId, userId) => {
    try {
      setIsLoading(true);
      await props.refreshTestApi(paramsId, userId);
      setRefresh((prev) => !prev);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to run test API:", error);
    }
  };

  const handleScrollToCard = (cardIndex) => {
    setSelectedCard(cardIndex);

    if (cardRefs.current[cardIndex]) {
      cardRefs.current[cardIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    setTimeout(() => {
      setSelectedCard(null);
    }, 3000);
  };

  return (
    <Box className="websecurity-scanning">
      {dataLoading ? (
        <div
          className="card"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Spinner height={100} width={100} />
        </div>
      ) : (
        <>
          {!immuniWeb?.id ? (
            <div className="card mt-5">
              <div className="card-body d-flex flex-column flex-grow-1 justify-content-center align-items-center">
                <div className="text-center">
                  <h5 className="text-muted mt-5">
                    No security data available for this asset. Please Run Scan
                    to get the security data.
                    {
                      <RunScan
                        loading={isLoading}
                        onClick={() =>
                          handleRunTestApi(props.paramsId, props.userId)
                        }
                      />
                    }
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card className="summary-container main-card">
                  <Card.Body>
                    <Box className="text-center mb-2">
                      {dataLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                          }}
                        >
                          <Skeleton variant="text" width={500} height={50} />
                        </Box>
                      ) : (
                        <h4 className="main-title">
                          Summary of{" "}
                          <a
                            href={immuniWeb?.url || ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {immuniWeb?.url}
                          </a>{" "}
                          Website Security Test
                        </h4>
                      )}
                      {dataLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                          }}
                        >
                          <Skeleton variant="text" width={400} height={50} />
                        </Box>
                      ) : (
                        <p className="main-subtitle">
                          <a
                            href={immuniWeb?.url || ""}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {immuniWeb?.url}
                          </a>{" "}
                          was tested {testCount} times during the last 12
                          months.
                        </p>
                      )}

                      <Row className="g-3 g-xxxl-5 mt-3">
                        <Col md={12} xl={5}>
                          <Card
                            className="mb-0 grade-card"
                            style={{
                              backgroundColor: summaryData?.grade?.startsWith(
                                "A"
                              )
                                ? "green" // Light Green
                                : summaryData?.grade?.startsWith("B")
                                ? "orange" // Light Yellow
                                : "red", // Light Red
                            }}
                          >
                            <Card.Body>
                              {dataLoading ? (
                                <Skeleton
                                  variant="text"
                                  width={100}
                                  height={200}
                                />
                              ) : (
                                <>
                                  <i className="grade">
                                    {summaryData && summaryData?.grade !== null
                                      ? `${summaryData?.grade}`
                                      : "-"}
                                  </i>
                                </>
                              )}
                              <Box className="text-center">
                                <p className="title">Your Final Score</p>
                                <p className="subtitle">Grade</p>
                              </Box>
                            </Card.Body>
                            <Avatar variant="rounded" className="icon-box">
                              <DesktopWindowsRoundedIcon />
                            </Avatar>
                          </Card>
                        </Col>
                        <Col md={5} xl={3}>
                          <Row className="g-4">{rensderReportCard}</Row>
                        </Col>

                        <Col md={7} xl={4}>
                          <Row className="g-4">{renderListCard}</Row>
                        </Col>
                      </Row>
                    </Box>

                    <Box className="row g-3 g-xxxl-5 mt-3">
                      <Box className="col-12 col-xxl-5">
                        <Box className="row g-3 g-xxxl-5">
                          <SummaryCardOneComponent
                            summaryData={summaryData}
                            dataLoading={dataLoading}
                            onCardClick={handleScrollToCard}
                          />
                        </Box>
                      </Box>
                      <Box className="col-12 col-xxl-7">
                        <Box className="row g-3 g-xxxl-5">
                          <SummaryCardTwoComponent
                            summaryData={summaryData}
                            dataLoading={dataLoading}
                            onCardClick={handleScrollToCard}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Card.Body>
                </Card>
              </Grid>

              {webServerSecurityTest && (
                <Grid item xs={12}>
                  <SecurityTestCardComponent
                    webServerSecurityTest={webServerSecurityTest}
                  />
                </Grid>
              )}

              {webSoftwareSecurityTest && (
                <Grid
                  item
                  xs={12}
                  xl={6}
                  ref={(el) => (cardRefs.current[1] = el)}
                >
                  <div
                    style={{
                      border: selectedCard === 1 ? "2px solid black" : "none",
                      transition: "border 0.3s ease",
                      borderRadius: "8px",
                    }}
                  >
                    <WebSoftwareSecurityTestComponent
                      webSoftwareSecurityTest={webSoftwareSecurityTest}
                    />
                  </div>
                </Grid>
              )}

              {/* {gdprComplianceTest?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  xl={6}
                  ref={(el) => (cardRefs.current[2] = el)}
                  style={{
                    border: selectedCard === 2 ? "2px solid black" : "none",
                  }}
                >
                  <GDPRComplianceTestComponent
                    gdprComplianceTest={gdprComplianceTest}
                  />
                </Grid>
              ) : (
                " "
              )} */}

              {gdprComplianceTest?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  xl={6}
                  ref={(el) => (cardRefs.current[2] = el)}
                >
                  <div
                    style={{
                      border: selectedCard === 2 ? "2px solid black" : "none",
                      transition: "border 0.3s ease",
                      borderRadius: "8px",
                    }}
                  >
                    <GDPRComplianceTestComponent
                      gdprComplianceTest={gdprComplianceTest}
                    />
                  </div>
                </Grid>
              ) : (
                " "
              )}

              {pcidssComplianceTest?.length > 0 ? (
                <Grid
                  item
                  xs={12}
                  xl={6}
                  ref={(el) => (cardRefs.current[3] = el)}
                >
                  <div
                    style={{
                      border: selectedCard === 3 ? "2px solid black" : "none",
                      transition: "border 0.3s ease",
                      borderRadius: "8px",
                    }}
                  >
                    <PCIDSSComplianceTestComponent
                      pcidssComplianceTest={pcidssComplianceTest}
                    />
                  </div>
                </Grid>
              ) : (
                " "
              )}
              {httpHeaderSecurityData &&
                httpHeaderSecurityData?.bodyInfo &&
                httpHeaderSecurityData?.bodyInfo?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    xl={6}
                    ref={(el) => (cardRefs.current[5] = el)}
                  >
                    <div
                      style={{
                        border: selectedCard === 5 ? "2px solid black" : "none",
                        transition: "border 0.3s ease",
                        borderRadius: "8px",
                      }}
                    >
                      <HTTPHeaderSecurityComponent
                        httpHeaderSecurityData={httpHeaderSecurityData}
                        MyListItem={MyListItem}
                      />
                    </div>
                  </Grid>
                )}

              {contentSecurityPolicyTest &&
                contentSecurityPolicyTest?.content_security_policy &&
                contentSecurityPolicyTest?.content_security_policy_report_only && (
                  <Grid
                    item
                    xs={12}
                    xl={6}
                    ref={(el) => (cardRefs.current[4] = el)}
                  >
                    <div
                      style={{
                        border: selectedCard === 4 ? "2px solid black" : "none",
                        transition: "border 0.3s ease",
                        borderRadius: "8px",
                      }}
                    >
                      <ContentSecurityPolicyTestComponent
                        contentSecurityPolicyTest={contentSecurityPolicyTest}
                      />
                    </div>
                  </Grid>
                )}
              {cookiesPrivacySecurityTest &&
                cookiesPrivacySecurityTest?.cookieInfo &&
                cookiesPrivacySecurityTest?.bodyInfo &&
                cookiesPrivacySecurityTest?.bodyInfo?.length > 0 && (
                  <Grid item xs={12} xl={6}>
                    <CookiePrivacyAndSecurityTestComponent
                      cookiesPrivacySecurityTest={cookiesPrivacySecurityTest}
                    />
                  </Grid>
                )}
            </Grid>
          )}
        </>
      )}

      <Modal
        show={showFirstModal}
        onHide={handleFirstModalClose}
        aria-labelledby="firstmodal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Security Scan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Flatpickr
            placeholder="Select date"
            className="form-control bg-light border-light border-0"
            value={scheduleDate}
            options={{
              enableTime: true,
              dateFormat: `${dateFormat} ${time}`,
              minDate: "today",
              minTime: scheduleDate ? getMinTime(scheduleDate) : initialMinTime,
            }}
            onChange={handleDate}
            style={{ borderRadius: "8px", padding: "0.7rem 0.9rem" }}
          /> */}
          <Flatpickr
            placeholder="Select date"
            className="form-control bg-light border-light border-0"
            value={scheduleDate}
            options={{
              enableTime: true,
              dateFormat: "Y-m-d H:i",
              ...dynamicOptions,
              // minDate: new Date(),
              // maxDate:
              //   securityScanLimitUnit === "Month"
              //     ? getEndOfMonth()
              //     : getEndOfDay(),
              // minTime: scheduleDate ? getMinTime() : "00:00",
              // maxTime: securityScanLimitUnit === "Month" ? "23:59" : "23:59",
            }}
            onChange={handleDate}
            style={{ borderRadius: "8px", padding: "0.7rem 0.9rem" }}
          />
          {scheduleErrorMessage !== null && (
            <p style={{ color: "red" }}>{scheduleErrorMessage}</p>
          )}
          <FormControl className="mt-3">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={scanType}
              onChange={handleScanTypeChange}
            >
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#1a2232",
                      },
                    }}
                  />
                }
                label="Single Scan"
                checked={scanType === "false"}
              />
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    sx={{
                      "&.Mui-checked": {
                        color: "#1a2232",
                      },
                    }}
                  />
                }
                label="Recurring Scan"
                checked={scanType === "true"}
              />
            </RadioGroup>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleFirstModalClose();
              setScanType("false");
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-dark"
            onClick={() => {
              if (!scheduleDate) {
                setScheduleErrorMessage("please select the date first");
              } else {
                postScheduleData(scanType);
              }
            }}
          >
            submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleCloseInput} centered>
        <ModalBody>
          <Form>
            <Box className="mb-3">
              <label htmlFor="choices-email-input">Email</label>
              <input
                id="choices-email-input"
                className="form-control bg-light border-light border-0"
                type="text"
                placeholder="Enter email address"
              />
            </Box>
            {error && <Box className="text-danger">{error}</Box>}
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleCloseInput}>
            Cancel
          </Button>
          <Button
            className="btn btn-dark"
            type="submit"
            onClick={handleSaveChangesClick}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        aria-labelledby="listsecuritydateLabel"
      >
        <Modal.Body className="p-2">
          <DataTable
            columns={columns}
            data={securityHistory}
            striped
            highlightOnHover
            pagination
          />
          <Box className="d-flex justify-content-center">
            <Button
              variant="link"
              className="link-success fw-medium"
              onClick={handleClose}
            >
              <i className="ri-close-line me-1 align-middle"></i> Close
            </Button>
          </Box>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default WebSecurityDetailPage;
