import axios from "axios";
import { decryptResponse } from "../Components/Common/common";
import {
  ADD_UPDATE_USER_SERVICES,
  GENERATE_INVOICE,
  GENERATE_INVOICE_REPORT,
  GET_ALL_SERVICES,
  GET_CUURENT_USAGE_USER_SERVICE,
  GET_USER_SERVICES,
} from "./ApiRoutes";

export const getAllServices = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_ALL_SERVICES)
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserServices = async (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${GET_USER_SERVICES}/${userId}`)
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addUpdateUserService = async (userId, servicePreferences) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${ADD_UPDATE_USER_SERVICES}`, { userId, servicePreferences })
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCurrentUsageService = async (userId, startDate, endDate) => {
  let url = `${GET_CUURENT_USAGE_USER_SERVICE}/${userId}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const generateInvoice = async (userId, startDate, endDate) => {
  let url = `${GENERATE_INVOICE}/${userId}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob", // Ensures the response is in binary (blob) format
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Create a URL for the Blob and trigger the download
        const fileURL = window.URL.createObjectURL(new Blob([response]));
        const fileName = `Invoice_${userId}_${Date.now()}.pdf`;

        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", fileName); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click(); // Simulate a click to start the download
        document.body.removeChild(link); // Clean up the link element

        resolve(response.data);
      })
      .catch((error) => {
        console.error("Error downloading the invoice:", error);
        reject(error);
      });
  });
};

export const generateInvoiceReport = async (userId, startDate, endDate) => {
  let url = `${GENERATE_INVOICE_REPORT}/${userId}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: "blob", // Ensures the response is in binary (blob) format
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Create a URL for the Blob and trigger the download
        const fileURL = window.URL.createObjectURL(new Blob([response]));
        const fileName = `report_${userId}_${Date.now()}.pdf`;

        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", fileName); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click(); // Simulate a click to start the download
        document.body.removeChild(link); // Clean up the link element

        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
