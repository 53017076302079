import { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LiveUsersByCountry from "../../NewDashboard/DashboardAnalyticTab/common/LiveUsersByCountry";
import {
  AnalyticsDataRadialChart,
  BarChart,
  AreaChart,
  DonutChart,
  GradientChart,
  SplineAreaChart,
} from "../../Charts/ApexCharts/LineCharts/LineCharts";
import DataTable from "react-data-table-component";
import common, { decryptResponse } from "../../../Components/Common/common";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  styled,
} from "@mui/material";
import {
  DesktopIcon,
  LinkIcon,
  MobileIcon,
  TabletIcon,
} from "../../../Components/Common/SvgIcons";
import { CustomTableStyle } from "../../../Components/Common/constants";
import {
  getAllAnalyticData,
  getAnalyticsChartData,
  getAnalyticsServices,
  getPackagesServices,
  getUserSessionChartData,
} from "../../../helpers/Help/CommomApi";
import google from "../../../assets/images/browsers/chrome.svg";
import safari from "../../../assets/images/browsers/safari.svg";
import brave from "../../../assets/images/browsers/brave.svg";
import edge from "../../../assets/images/browsers/edge.svg";
import samsung from "../../../assets/images/browsers/samsung.svg";
import firefox from "../../../assets/images/browsers/firefox.svg";
import android from "../../../assets/images/browsers/android.svg";
import opera from "../../../assets/images/browsers/opera.svg";
import ucBrowser from "../../../assets/images/browsers/uc-browser.svg";
import { getVisitorData } from "../../../Api/NewDashboard/DashboardAnalyticTab/DvdlTabsReq";
import { getCurrentUsageService } from "../../../Api/UserServicesSettingsApi";

const HitReportDetails = () => {
  const params = useParams();
  const { id } = params;

  // Pagination
  const [currentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [maxValue, setMaxValue] = useState(0);
  const [selectedDays, setSelectedDays] = useState(null);
  const [analyticsServices, setAnalyticsServices] = useState([]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [analyticsData, setAnalyticsData] = useState([]);
  const [fetch_analytics_data_loading, set_fetch_analytics_data_loading] =
    useState(false);
  const [analyticsChartData, setAnalyticsChartData] = useState([]);
  // eslint-disable-next-line
  const [fetch_chart_data_loading, set_fetch_chart_data_loading] =
    useState(false);
  const [userSessionChartData, setUserSessionChartData] = useState([]);
  // eslint-disable-next-line
  const [
    fetch_user_session_chart_loading,
    set_fetch_user_session_chart_loading,
  ] = useState(false);
  const [device, setDevice] = useState({ desktop: 0, tablet: 0, mobile: 0 });

  const { loading, currentUserId, currentUserRole } = useSelector((state) => ({
    loading: state.analyticsData.loading,
    fetch_analytics_realtime_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_data_loading || false,
    fetch_analytics_realtime_duration_data_loading:
      state?.analyticsData?.fetch_analytics_realtime_duration_data_loading ||
      false,
    currentUserId: state.userDetails?.userData?.id,
    currentUserRole: state.userDetails?.userData?.role,
  }));

  const acquisitionData = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.acquisitions_report
        ? JSON.parse(analyticsChartData[0]?.acquisitions_report)
        : [];
  }, [analyticsChartData]);

  const reportByPathData = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_path
        ? JSON.parse(analyticsChartData[0]?.report_by_path)
        : [];
  }, [analyticsChartData]);

  const reportByPageTitle = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_title
        ? JSON.parse(analyticsChartData[0]?.report_by_title)
        : [];
  }, [analyticsChartData]);

  const reportByGender = useMemo(() => {
    if (analyticsChartData !== undefined)
      return analyticsChartData[0]?.report_by_gender
        ? JSON.parse(analyticsChartData[0]?.report_by_gender)
        : [];
  }, [analyticsChartData]);

  useEffect(() => {
    const data = analyticsData?.usersByDevice || {};
    const desktop = parseInt(data.desktop) || 0;
    const mobile = parseInt(data.mobile) || 0;
    const tablet = parseInt(data.tablet) || 0;

    const total = desktop + tablet + mobile;
    if (total > 0) {
      setDevice({
        desktop: ((desktop / total) * 100).toFixed(2),
        tablet: ((tablet / total) * 100).toFixed(2),
        mobile: ((mobile / total) * 100).toFixed(2),
      });
    }
  }, [analyticsData]);

  const getAllHitReportData = async (day = null, assets_id) => {
    set_fetch_analytics_data_loading(true);
    try {
      let response = await getAllAnalyticData({ day, assets_id });
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response?.status === "success") setAnalyticsData(response?.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      set_fetch_analytics_data_loading(false);
    }
  };

  const getAllUserSessionChartData = async (assets_id) => {
    set_fetch_user_session_chart_loading(true);
    try {
      let response = await getUserSessionChartData({ assets_id });
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response?.status === "success")
        setUserSessionChartData(response?.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      set_fetch_user_session_chart_loading(false);
    }
  };

  const getAllAnalyticChartData = async (day = null, assets_id) => {
    set_fetch_chart_data_loading(true);
    try {
      let response = await getAnalyticsChartData({ day, assets_id });
      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;
      if (response?.status === "success") setAnalyticsChartData(response?.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      set_fetch_chart_data_loading(false);
    }
  };

  useEffect(() => {
    getAllHitReportData(selectedDays, params.id);
    getAllAnalyticChartData(selectedDays, params.id);
    // eslint-disable-next-line
  }, [params.id, selectedDays]);

  useEffect(() => {
    getAllUserSessionChartData(params.id);
    // eslint-disable-next-line
  }, [params.id]);

  // const fetchPackageServiceDataByUserId = async (user_id) => {
  //   try {
  //     const response = await getPackagesServices(user_id);
  //     const responseData = common.decrypt(response.data);
  //     if (responseData) {
  //       const services = responseData?.[0].packageServiceData.find(
  //         (item) => item.slug === "analytics_services"
  //       )?.value;
  //       setAnalyticsServices(services);
  //     }
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  // useEffect(() => {
  //   if (currentUserId) fetchPackageServiceDataByUserId(currentUserId);
  // }, [currentUserId]);

  // Fetch services and user preferences
  const fetchUserAccountData = async (id) => {
    try {
      const response = await getCurrentUsageService(813);
      const analyticsResponse = response?.find(
        (item) => item.slug === "analytics_reports"
      );

      let settings = analyticsResponse?.settings || {};
      if (typeof settings === "string") {
        try {
          settings = JSON.parse(settings);
        } catch (error) {
          console.error("Failed to parse settings:", error);
          settings = {};
        }
      }

      const userServices = settings?.analytics_services || [];

      const res = await getAnalyticsServices();
      const responseData = decryptResponse(res?.data);

      const services = [];
      // Iterate directly over the responseData array
      responseData.forEach((service) => {
        if (userServices.includes(service.s_service_id)) {
          services.push(service.s_service_name);
        }
      });

      setAnalyticsServices(services);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (currentUserId) fetchUserAccountData(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    if (Array.isArray(acquisitionData) && acquisitionData.length > 0) {
      const maxTotalUsers = Math.max(
        ...acquisitionData.map((row) => row.totalUsers)
      );
      const maxConversions = Math.max(
        ...acquisitionData.map((row) => row.conversions)
      );
      setMaxValue(Math.max(maxTotalUsers, maxConversions));
    } else if (Array.isArray(reportByPathData) && reportByPathData.length > 0) {
      const maxSessions = Math.max(
        ...reportByPathData.map((row) => row.sessions)
      );
      setMaxValue(maxSessions);
    }
  }, [acquisitionData, reportByPathData]);

  const handleClickDays = (days) => {
    setSelectedDays(days);
  };

  const sessionColumns = [
    {
      name: "Sessions",
      value:
        analyticsData?.avg_visits !== null &&
        analyticsData?.avg_visits !== undefined
          ? common.formatNumberWithCommas(analyticsData?.avg_visits)
          : "-",
    },
    {
      name: "Pages/Sessions",
      value:
        analyticsData?.pages_per_session !== null &&
        analyticsData?.pages_per_session !== undefined
          ? analyticsData?.pages_per_session
          : "-",
    },
    {
      name: "Avg. Session Duration",
      value:
        analyticsData?.avg_avg_session_duration !== null &&
        analyticsData?.avg_avg_session_duration !== undefined
          ? analyticsData?.avg_avg_session_duration
          : "-",
    },
    {
      name: "Bounce Rate",
      value:
        analyticsData?.avg_bounce_rate !== null &&
        analyticsData?.avg_bounce_rate !== undefined
          ? analyticsData?.avg_bounce_rate + " %"
          : "-",
    },
    {
      name: "Pageviews",
      value:
        analyticsData?.avg_pageviews !== null &&
        analyticsData?.avg_pageviews !== undefined
          ? analyticsData?.avg_pageviews
          : "-",
    },
  ];

  const columns = [
    {
      name: <span>User Medium</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row.firstUserDefaultChannelGroup}</span>
        </span>
      ),
      grow: 2,
      width: "160px",
    },
    {
      name: <span>Total Users</span>,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <span className="me-2">{row?.totalUsers}</span>
          <ProgressBar
            now={(row.totalUsers / maxValue) * 100}
            className="user-progressbar"
            style={{ width: "150px", height: "17px", minWidth: "150px" }}
          />
        </div>
      ),
      center: true,
    },
    {
      name: <span>Conversions</span>,
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <span className="me-2">{row?.conversions}</span>
          <ProgressBar
            now={(row.conversions / maxValue) * 100}
            className="conversions-progressbar"
            style={{ width: "150px", height: "17px", minWidth: "150px" }}
          />
        </div>
      ),
      center: true,
    },
  ];

  const reportByPathColumns = [
    {
      name: <span>Page</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row?.unifiedPagePathScreen}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Pages/Sessions</span>,
      selector: (row) => parseFloat(row?.screenPageViewsPerSession).toFixed(2),
      center: true,
    },
    {
      name: <span>Sessions</span>,
      selector: (row) => parseInt(row?.sessions),
      center: true,
    },
    {
      name: <span>Avg. Session Duration</span>,
      selector: (row) => parseFloat(row?.averageSessionDuration).toFixed(2),
      center: true,
    },
    {
      name: <span>New Sessions</span>,
      selector: (row) => parseInt(row?.newUsers),
      center: true,
    },
    {
      name: <span>Bounce Rate</span>,
      selector: (row) => parseFloat(row?.bounceRate).toFixed(2),
      center: true,
    },
  ];

  const reportByPageTileColumn = [
    {
      name: <span>Page Titles</span>,
      selector: (row) => (
        <span>
          <span className="fw-bold">{row?.PageTitle}</span>
        </span>
      ),
      grow: 2,
    },
    {
      name: <span>Sessions</span>,
      selector: (row) => parseInt(row?.sessions),
      center: true,
    },
    {
      name: <span>Avg. Session Duration</span>,
      selector: (row) => parseFloat(row?.averageSessionDuration).toFixed(2),
      center: true,
    },
    {
      name: <span>Bounce Rate</span>,
      selector: (row) => parseFloat(row?.bounceRate).toFixed(2),
      center: true,
    },
  ];

  const reportBySessionColumns = [
    {
      name: <span>Sessions</span>,
      cell: (row) => (
        <Row className="align-items-center py-2 w-100">
          <Col lg={6} className="mb-2">
            <p className="mb-0 fw-medium">{row?.unifiedPagePathScreen}</p>
          </Col>
          <Col lg={6} className="mb-2 text-end">
            <p className="mb-0 fw-semibold">{row?.sessions}</p>
          </Col>
          <Col lg={12} className="mb-2">
            <div className="progress animated-progress">
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{
                  width: `${(row.sessions / maxValue) * 100}%`,
                }}
                aria-valuenow={row.sessions}
                aria-valuemin="0"
                aria-valuemax={maxValue}
              ></div>
            </div>
          </Col>
        </Row>
      ),
      width: "100%",
      minWidth: "100%",
    },
  ];

  // Pagination
  const currentData = reportByPathData?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculations for reportByGender
  const totalSessions = reportByGender?.reduce(
    (acc, curr) => acc + parseInt(curr.sessions),
    0
  );
  const totalUsers = reportByGender?.reduce(
    (acc, curr) => acc + parseInt(curr.newUsers),
    0
  );
  const averageBounceRate =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.bounceRate),
      0
    ) / reportByGender?.length;
  const averagePagesPerSession =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.screenPageViewsPerSession),
      0
    ) / reportByGender?.length;
  const averageSessionDuration =
    reportByGender?.reduce(
      (acc, item) => acc + parseFloat(item.averageSessionDuration),
      0
    ) / reportByGender?.length;

  const handleClick = () => {
    const url = analyticsData?.url || "";
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const statusOptions = [
    { value: "1", label: "Today" },
    { value: "7", label: "Last 7 Days" },
    { value: "30", label: "Last 30 Days" },
    { value: "180", label: "Last 6 Months" },
    { value: "365", label: "Last 1 Year" },
  ];

  //Search Engine
  const browserReport = analyticsData?.reportByBrowser?.averageData
    ? JSON.parse(analyticsData?.reportByBrowser?.averageData)
    : [];

  let browserType = (data) => {
    let newData;

    switch (data?.browser) {
      case "Chrome":
        newData = google;
        break;
      case "Safari":
      case "Safari (in-app)":
        newData = safari;
        break;
      case "Edge":
        newData = edge;
        break;
      case "Samsung Internet":
        newData = samsung;
        break;
      case "Firefox":
      case "Mozilla Compatible Agent":
        newData = firefox;
        break;
      case "Android Webview":
        newData = android;
        break;
      case "Opera":
        newData = opera;
        break;
      case "UC Browser":
        newData = ucBrowser;
        break;
      default:
        newData = brave;
        break;
    }

    return newData;
  };

  const ScrollableBox = styled(Box)({
    height: "440px",
    overflowY: "auto",
  });

  // Calculate total active users
  const totalActiveUsers = browserReport.reduce(
    (sum, item) => sum + JSON.parse(item.activeUsers),
    0
  );

  // Calculate percentage for each browser
  const dataWithPercentage = browserReport.map((item) => ({
    ...item,
    percentage: ((item.activeUsers / totalActiveUsers) * 100).toFixed(2),
  }));

  const renderAnalytics =
    // loading || fetch_analytics_data_loading ? (
    fetch_analytics_data_loading ? (
      // Show skeleton loader when data is loading
      <>
        {Array.from(new Array(4)).map((_, index) => (
          <Box className="analytics-bar" key={index}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex align-items-center">
                <Skeleton variant="circular" width={40} height={40} />
                <Box sx={{ ml: 2 }}>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={60} />
                </Box>
              </Box>
              <Skeleton variant="text" width={30} />
            </Box>
          </Box>
        ))}
      </>
    ) : dataWithPercentage?.length > 0 ? (
      // Show actual data when it is available
      dataWithPercentage?.map((data, index) => {
        return (
          <Box className="analytics-bar" key={data?.id || index}>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex align-items-center">
                <img alt="logo" className="avatar" src={browserType(data)} />
                <Box>
                  <h6 className="title mb-0">{data?.browser}</h6>
                  <p className="subtitle mb-0">{data?.subtitle}</p>
                </Box>
              </Box>
              <span>
                <h6
                  className="value"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: "1px",
                    lineHeight: "0.5",
                  }}
                >
                  {data?.percentage}
                  <span style={{ fontSize: "14px", paddingRight: "15px" }}>
                    %
                  </span>
                </h6>
              </span>
            </Box>
          </Box>
        );
      })
    ) : (
      // Show "No Data Available" message when no data is found
      <Box
        className="no-data-box"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          margin: "20px",
          marginTop: "180px",
        }}
      >
        <h5>No Data Available</h5>
        <p>Please check back later for updated analytics.</p>
      </Box>
    );

  // Visitors chart
  const [visitorData, setVisitorData] = useState(7);

  const getVisitors = async (id, days) => {
    try {
      const data = await getVisitorData(id, parseInt(days));
      setVisitorData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const daysToFetch = selectedDays || "30";
  useEffect(() => {
    getVisitors(id, daysToFetch);
  }, [id, daysToFetch]);

  //Modified one
  return (
    <>
      <Row className="g-4 mb-4">
        <Col lg={12}>
          <Box
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              wordBreak: "break-all",
            }}
          >
            {fetch_analytics_data_loading ? (
              <Skeleton width={200} height={30} />
            ) : (
              <Link to="#">{analyticsData?.url || "No URL"}</Link>
            )}
            {/* <Link to="#">{analyticsData?.url || "No URL"}</Link> */}
            <IconButton
              onClick={handleClick}
              sx={{
                color: "#98a2b5",
                marginLeft: "20px",
                "&.MuiButtonBase-root": {
                  "svg path": {
                    fill: "#98a2b5",
                    stroke: "#98a2b5",
                  },
                },
              }}
            >
              <LinkIcon width={24} height={24} />
            </IconButton>
          </Box>

          <Box>
            <FormControl
              fullWidth
              variant="filled"
              sx={{ width: "175px", display: "inline-block" }}
            >
              <Select
                displayEmpty
                fullWidth
                value={selectedDays || ""}
                onChange={(e) => handleClickDays(e.target.value)}
                sx={{
                  "& .MuiSelect-select": {
                    paddingTop: "9px",
                    background: "transparent",
                    fontSize: "14px",
                  },
                  "&.MuiInputBase-root": {
                    borderRadius: "8px",
                    background: "#fff",
                    "&::before, &::after": {
                      borderBottom: "0 !important",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select the days
                </MenuItem>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Col>
      </Row>

      <Box className="report-grid-card mb-4">
        {Array.isArray(sessionColumns) &&
          sessionColumns.length > 0 &&
          sessionColumns.map((item, index) => (
            <Box key={index} className="item">
              <Card>
                <Card.Header>
                  <Card.Title
                    style={{
                      fontSize: "16px",
                      marginBottom: "15px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </Card.Title>
                </Card.Header>
                <Card.Body style={{ textAlign: "center" }}>
                  {loading || item.value === "-" ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className="mb-3"
                    >
                      <Skeleton variant="text" width={100} height={30} />
                    </Box>
                  ) : (
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "26px",
                        marginBottom: 0,
                      }}
                    >
                      {/* {item.value} */}
                      {item.value !== null && item.value !== undefined
                        ? item.value
                        : "-"}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </Box>
          ))}
      </Box>

      <Row className="g-4 mb-4">
        <Col xl={4}>
          {analyticsServices?.includes("Users & Sessions report") ||
          currentUserRole === "Super Admin" ? (
            <Card>
              <Card.Header>
                <h4 className="card-title">Session by Device</h4>
              </Card.Header>
              <Card.Body>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {fetch_analytics_data_loading ? (
                    <Skeleton variant="circular" width={300} height={300} />
                  ) : (
                    <DonutChart
                      width="300px"
                      height="300px"
                      loading={fetch_analytics_data_loading}
                      data={analyticsData?.usersByDevice}
                    />
                  )}
                </Box>

                <Box className="d-flex align-content-start justify-content-center gap-3 mt-3">
                  {/* Skeletons for Device Usage */}
                  {fetch_analytics_data_loading ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={100}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={100}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={100}
                        height={100}
                      />
                    </>
                  ) : (
                    <>
                      {device?.desktop > 0 && (
                        <Box className="pagespeed-card">
                          <DesktopIcon />
                          <h4 className="time">{`${device.desktop}%`}</h4>
                          <p className="title">Desktop</p>
                        </Box>
                      )}
                      {device?.tablet > 0 && (
                        <Box className="pagespeed-card">
                          <TabletIcon />
                          <h4 className="time">{`${device.tablet}%`}</h4>
                          <p className="title">Tablet</p>
                        </Box>
                      )}
                      {device?.mobile > 0 && (
                        <Box className="pagespeed-card">
                          <MobileIcon />
                          <h4 className="time">{`${device.mobile}%`}</h4>
                          <p className="title">Mobile</p>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Card.Body>
            </Card>
          ) : (
            <>
              <Box
                sx={{
                  height: "400px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  fontSize: "1.2rem",
                  position: "relative",
                }}
              >
                <p
                  className="card-title"
                  style={{
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    margin: 0,
                  }}
                >
                  Session by Device
                </p>
                <b>Upgrade your plan</b>
              </Box>
            </>
          )}
        </Col>

        <Col xl={8}>
          {analyticsServices?.includes("Real-time Page Visitor") ||
          currentUserRole === "Super Admin" ? (
            <LiveUsersByCountry />
          ) : (
            <Box
              sx={{
                height: "400px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Live user by Country
              </p>
              <b>Upgrade you plan</b>
            </Box>
          )}
        </Col>
      </Row>

      <Row className="g-4 mb-4">
        {analyticsServices.includes("Audience analysis") ||
        currentUserRole === "Super Admin" ? (
          // acquisitionData?.length > 0 && (
          <Col xl={6}>
            <Card className="p-2">
              <Card.Header>
                <h4 className="card-title">Search Engines</h4>
              </Card.Header>
              <Card.Body>
                {" "}
                <ScrollableBox>{renderAnalytics}</ScrollableBox>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          // )
          <Col xxl={6} xl={12}>
            <Box
              sx={{
                height: "385px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Search Engines
              </p>
              <b>Upgrade you plan</b>
            </Box>
          </Col>
        )}

        {analyticsServices.includes("Users & Sessions report") ||
        currentUserRole === "Super Admin" ? (
          acquisitionData?.length > 0 && (
            <Col xl={6}>
              <Card className="p-2">
                <Card.Header className="d-flex align-items-start justify-content-between flex-wrap">
                  <h4 className="card-title">Visitors</h4>
                </Card.Header>
                <Card.Body>
                  <SplineAreaChart data={visitorData} filter={daysToFetch} />
                </Card.Body>
              </Card>
            </Col>
          )
        ) : (
          <Col xxl={6} xl={12}>
            <Box
              sx={{
                height: "385px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Search Engines
              </p>
              <b>Upgrade you plan</b>
            </Box>
          </Col>
        )}
      </Row>

      <Row className="g-4 mb-4">
        {analyticsChartData?.[0]?.acquisitions_report && (
          <Col xl={6}>
            {analyticsServices?.includes("Acquisitions Report") ||
            currentUserRole === "Super Admin" ? (
              <Card>
                <Card.Header>
                  <h4 className="card-title">Acquisitions Report</h4>
                </Card.Header>
                <AnalyticsDataRadialChart data={analyticsChartData} />
              </Card>
            ) : (
              // <Skeleton variant="rounded" width={900} height={500}>
              //   Upgrade your plan - session
              // </Skeleton>

              <Box
                sx={{
                  height: "385px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  fontSize: "1.2rem",
                  position: "relative",
                }}
              >
                <p
                  className="card-title"
                  style={{
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    margin: 0,
                  }}
                >
                  Acquisitions Report
                </p>
                <b>Upgrade you plan</b>
              </Box>
            )}
          </Col>
        )}
        <Col xl={6}>
          {analyticsServices?.includes("Users & Sessions report") ||
          currentUserRole === "Super Admin" ? (
            <Card>
              <Card.Header>
                <h4 className="card-title">Users & Sessions report</h4>
              </Card.Header>
              <AreaChart data={userSessionChartData} />{" "}
            </Card>
          ) : (
            <Box
              sx={{
                height: "385px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Users & Sessions report
              </p>
              <b>Upgrade you plan</b>
            </Box>
          )}
        </Col>
      </Row>

      <Row className="g-4 mb-4">
        {analyticsServices.includes("Acquisitions Report") ||
        currentUserRole === "Super Admin" ? (
          acquisitionData?.length > 0 && (
            <Col xxl={6} xl={12}>
              <Card>
                <Card.Body>
                  <DataTable
                    columns={columns}
                    data={acquisitionData}
                    fixedHeader
                    fixedHeaderScrollHeight="700px"
                    pagination
                  />
                </Card.Body>
              </Card>
            </Col>
          )
        ) : (
          <Col xxl={6} xl={12}>
            <Box
              sx={{
                height: "385px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Acquisitions Report
              </p>
              <b>Upgrade you plan</b>
            </Box>
          </Col>
        )}

        {analyticsServices.includes("Users & Sessions report") ||
        currentUserRole === "Super Admin" ? (
          currentData?.length > 0 &&
          reportBySessionColumns && (
            <Col xxl={6} xl={12}>
              <Card>
                <Card.Body>
                  <DataTable
                    columns={reportBySessionColumns}
                    data={currentData}
                    fixedHeader
                    fixedHeaderScrollHeight="700px"
                    pagination
                    paginationRowsPerPageOptions={[5]}
                    className="session-table"
                  />
                </Card.Body>
              </Card>
            </Col>
          )
        ) : (
          <Col xxl={6} xl={12}>
            <Box
              sx={{
                height: "385px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Report by Session
              </p>
              <b>Upgrade you plan</b>
            </Box>
          </Col>
        )}
      </Row>

      <Row className="g-4 mb-4">
        {analyticsServices.includes("Page Tracking Report") ||
        currentUserRole === "Super Admin" ? (
          reportByPathData?.length > 0 && (
            <Col xxl={12} xl={12}>
              <Card>
                <Card.Header>
                  <h4 className="card-title">Report by path</h4>
                </Card.Header>
                <Card.Body>
                  <DataTable
                    columns={reportByPathColumns}
                    data={reportByPathData}
                    fixedHeader
                    fixedHeaderScrollHeight="700px"
                    pagination
                    customStyles={CustomTableStyle}
                    className="table-design"
                  />
                </Card.Body>
              </Card>
            </Col>
          )
        ) : (
          <Box
            sx={{
              height: "385px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              fontSize: "1.2rem",
              position: "relative",
            }}
          >
            <p
              className="card-title"
              style={{
                position: "absolute",
                top: "16px",
                left: "16px",
                margin: 0,
              }}
            >
              Report by path
            </p>
            <b>Upgrade you plan</b>
          </Box>
        )}

        {analyticsServices.includes("Page Tracking Report") ||
        currentUserRole === "Super Admin" ? (
          reportByPageTitle?.length > 0 && (
            <Col xxl={12} xl={12}>
              <Card>
                <Card.Header>
                  <h4 className="card-title">Top Landing Pages</h4>
                </Card.Header>
                <Card.Body>
                  <DataTable
                    columns={reportByPageTileColumn}
                    data={reportByPageTitle}
                    fixedHeader
                    fixedHeaderScrollHeight="700px"
                    pagination
                    customStyles={CustomTableStyle}
                    className="table-design"
                  />
                </Card.Body>
              </Card>
            </Col>
          )
        ) : (
          <Box
            sx={{
              height: "385px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              fontSize: "1.2rem",
              position: "relative",
            }}
          >
            <p
              className="card-title"
              style={{
                position: "absolute",
                top: "16px",
                left: "16px",
                margin: 0,
              }}
            >
              Top Landing Pages
            </p>
            <b>Upgrade you plan</b>
          </Box>
        )}
      </Row>

      {(totalSessions ||
        totalUsers ||
        averageBounceRate ||
        averagePagesPerSession ||
        averageSessionDuration) && (
        <Row className="g-4 mb-4">
          <Col xxl={12} xl={12}>
            {analyticsServices?.includes("Audience analysis") ||
            currentUserRole === "Super Admin" ? (
              <Card>
                <Card.Header>
                  <h4 className="card-title">Report By Gender</h4>
                </Card.Header>
                <Card.Body>
                  <div className="table-responsive table-design report-table">
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr>
                          <th rowSpan="2">Gender</th>
                          <th colSpan="3">Acquisition</th>
                          <th colSpan="3">Behavior</th>
                        </tr>
                        <tr>
                          <th>Sessions</th>
                          <th>% New Sessions</th>
                          <th>New Users</th>
                          <th>Bounce Rate</th>
                          <th>Pages/Session</th>
                          <th>Avg. Session Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>{`${common.formatNumberWithCommas(
                            totalSessions
                          )} (total)`}</td>
                          <td>-</td>
                          <td>{`${totalUsers} (total)`}</td>
                          <td>{`${parseFloat(averageBounceRate).toFixed(
                            2
                          )} (Avg.)`}</td>
                          <td>{`${parseFloat(averagePagesPerSession).toFixed(
                            2
                          )} (Avg.)`}</td>
                          <td>{`${parseFloat(averageSessionDuration).toFixed(
                            2
                          )} (Avg.)`}</td>
                        </tr>
                        {Array.isArray(reportByGender) &&
                          reportByGender.length > 0 &&
                          reportByGender.map((item, index) => {
                            const percentage =
                              (item.sessions / totalSessions) * 100;
                            return (
                              <tr key={index}>
                                <td>
                                  {item.userGender === "unknown"
                                    ? "Other"
                                    : item.userGender}
                                </td>
                                <td>{`${item.sessions} (${percentage.toFixed(
                                  2
                                )}%)`}</td>
                                <td>{item.newUsers}</td>
                                <td>{item.newUsers}</td>
                                <td>
                                  {parseFloat(item.bounceRate).toFixed(2)}
                                </td>
                                <td>
                                  {parseFloat(
                                    item.screenPageViewsPerSession
                                  ).toFixed(2)}
                                </td>
                                <td>
                                  {parseFloat(
                                    item.averageSessionDuration
                                  ).toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <Box
                sx={{
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  fontSize: "1.2rem",
                  position: "relative",
                }}
              >
                <p
                  className="card-title"
                  style={{
                    position: "absolute",
                    top: "16px",
                    left: "16px",
                    margin: 0,
                  }}
                >
                  Report By Gender
                </p>
                <b>Upgrade you plan</b>
              </Box>
            )}
          </Col>
        </Row>
      )}

      <Row className="g-4">
        <Col xl={6}>
          {analyticsServices?.includes("Users & Sessions report") ||
          currentUserRole === "Super Admin" ? (
            <Card>
              <Card.Header>
                <h4 className="card-title">Sessions</h4>
              </Card.Header>
              <GradientChart data={analyticsData?.sessionChart || []} />
            </Card>
          ) : (
            <Box
              sx={{
                height: "450px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                fontSize: "1.2rem",
                position: "relative",
              }}
            >
              <p
                className="card-title"
                style={{
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  margin: 0,
                }}
              >
                Session
              </p>
              <b>Upgrade you plan</b>
            </Box>
          )}
        </Col>

        <Col xl={6}>
          <Card>
            <Card.Header>
              <h4 className="card-title">Pageviews</h4>
            </Card.Header>
            <BarChart data={analyticsData?.pageviewChart || []} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HitReportDetails;
