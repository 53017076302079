import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Skeleton,
  Button,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getPackagesServices,
  getUserPackageLimit,
  updatePurchaseStatus,
} from "../../helpers/Help/CommomApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MyPackage from "./MyPackage";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import common from "../../Components/Common/common";
import { getUserId } from "../../Api/common";
import { getPurchaseStausOfAdmin } from "../../Api/myPackageApi";
import { ToastMessage } from "../../Components/Common/ToastMessage";

const AllPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.userDetails.userData);
  const userId = getUserId();
  const userRole = userData?.role;
  const userIsAdmin = userData?.isAdmin;
  const userDepartment = userData?.department_id;
  const { selectedPlan } = location.state || {};

  const [packageServiceData, setPackageServiceData] = useState([]);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState({});
  const [isYearly, setIsYearly] = useState(true);
  const [myPackage, setMyPackage] = useState([]);
  const [currentUserPackageData, setCurrentUserPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [cancelReqPopup, setCancelReqPopup] = useState(false);
  const [extraServicePopup, setExtraServicePopup] = useState(false);
  const [cancelCurrentReqPopup, setCancelCurrentReqPopup] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState(null);

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleCancelReqPopupClose = () => {
    setCancelReqPopup(false);
  };

  const handleCancelCurrentReqPopupClose = () => {
    setCancelCurrentReqPopup(false);
  };

  const handleExtraServicePopupClose = () => {
    setExtraServicePopup(false);
  };

  const handleContinue = () => {
    setShowPopup(false);
    navigateToContactUs(myPackage[0]);
  };

  const handleCancelReqPopupContinue = async () => {
    const response = await updatePurchaseStatus(
      purchaseHistoryData.id,
      "Cancelled"
    );
    if (response.status === "success") {
      ToastMessage.Success("Current request cancelled.");
      fetchPurchaseStatusOfAdmin();
      setCancelReqPopup(false);
    }
  };

  const handleCancelCurrentReqPopupContinue = async () => {
    const response = await updatePurchaseStatus(
      purchaseHistoryData.id,
      "Cancelled"
    );
    if (response.status === "success") {
      ToastMessage.Success("Current request cancelled.");
      fetchPurchaseStatusOfAdmin();
      setCancelCurrentReqPopup(false);
      navigateToContactUs(currentSelectedPlan);
    }
  };

  const cardRefs = useRef([]);

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const fetchPackageServiceData = async () => {
    try {
      const response = await getPackagesServices();
      const responseData = common.decrypt(response.data);
      setPackageServiceData(responseData);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchPackageServiceDataByUserId = async (userId) => {
    try {
      const response = await getPackagesServices(userId);
      const responseData = common.decrypt(response.data);
      setMyPackage(responseData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchPackageServiceData();
  }, []);

  useEffect(() => {
    fetchPackageServiceDataByUserId(userId);
  }, [userId]);

  const renderValue = (service, plan, isMyPackage = false) => {
    if (service.slug === "uptime_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_interval"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_unit"
        )?.value;
        return `${interval} ${unit.toLowerCase()}. monitoring interval`;
      }
      return "Not Available";
    } else if (service.slug === "performance_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit_unit"
        )?.value;
        return `${interval} / ${unit}`;
      }
      return "Not Available";
    } else if (service.slug === "security_scan") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit"
        )?.value;
        const currentUsedLimit =
          currentUserPackageData?.security_scan_report_limit || 0;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit_unit"
        )?.value;
        return isMyPackage
          ? `${currentUsedLimit} / ${interval} ${unit}`
          : `${interval} / ${unit}`;
      }
      return "Not Available";
    } else if (service.slug === "analytics_reports") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "analytics_services"
        )?.value;

        return `${interval}`;
      }
      return "Not Available";
    }

    if (service.value === null) return "Not Available";
    if (service.value === "true") return "Yes";
    if (service.value === "false") return "No";
    if (Array.isArray(service.value)) return service.value.join(", ");
    return service.value;
  };

  const shouldDisplayService = (service) => {
    return ![
      "uptime_monitoring_interval",
      "uptime_monitoring_unit",
      "performance_report_limit",
      "performance_report_limit_unit",
      "security_scan_report_limit",
      "security_scan_report_limit_unit",
      "schedule_security_report_limit",
      "schedule_security_report_limit_unit",
      "analytics_services",
      "custom_package",
    ].includes(service.slug);
  };

  const isCurrentPackage = (plan) => {
    return myPackage && plan?.packageId === myPackage[0]?.packageId;
  };

  const isInCurrentPlanHistory = (plan) => {
    if (
      purchaseHistoryData &&
      purchaseHistoryData.package_id === plan.packageId
    ) {
      if (
        purchaseHistoryData.package_status !== "Active" ||
        purchaseHistoryData.package_status !== "Pending"
      ) {
        return true;
      }
    }

    return false;
  };

  const fetchUserPackageLimit = async (user_id) => {
    try {
      let response = await getUserPackageLimit(user_id);
      const responseData = common.decrypt(response.data);
      if (
        responseData?.package_end_date &&
        responseData?.package_end_date !== null
      ) {
        const packagEndDate =
          formatDateTime(
            responseData?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedDateFinal +
          " - " +
          formatDateTime(
            responseData?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedTimed;

        responseData["package_end_date"] = packagEndDate || null;
      }
      setCurrentUserPackageData(responseData || null);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchPurchaseStatusOfAdmin = async () => {
    try {
      await getPurchaseStausOfAdmin()
        .then((res) => {
          setPurchaseHistoryData(res[0] || {});
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {});
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchPurchaseStatusOfAdmin();
    fetchUserPackageLimit(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPlan && selectedPlan?.package_id) {
      const matchingCardIndex = packageServiceData.findIndex(
        (plan) => plan.packageId === selectedPlan?.package_id
      );

      if (matchingCardIndex !== -1 && cardRefs.current[matchingCardIndex]) {
        cardRefs.current[matchingCardIndex].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [selectedPlan, packageServiceData]);

  const isPackageExpired = currentUserPackageData?.isPackageExpired;

  const isPlanUpgradeable = (currentPackage, selectedPlan) => {
    if (!currentPackage || !selectedPlan) return false;

    const getPackageById = (packageId) =>
      packageServiceData.find((pkg) => pkg.packageId === packageId);

    const selectedPackage = getPackageById(selectedPlan.packageId);
    if (!currentUserPackageData || !selectedPackage) return false;

    const getServiceValue = (pkg, slug) =>
      parseInt(
        pkg.packageServiceData.find((service) => service.slug === slug)
          ?.value || 0
      );

    const currentAssets = currentUserPackageData.assets || 0;
    const currentUserLimits = currentUserPackageData.users_limits || 0;

    const selectedAssets = getServiceValue(selectedPackage, "assets");
    const selectedUserLimits = getServiceValue(selectedPackage, "users_limits");

    if (currentUserPackageData.package_id === selectedPackage.packageId) {
      const assetsExceeded = currentAssets > selectedAssets;
      const usersExceeded = currentUserLimits > selectedUserLimits;

      return {
        isUpgradeable: true,
        requiresConfirmation: assetsExceeded || usersExceeded,
      };
    }

    return {
      isUpgradeable:
        selectedAssets >= currentAssets &&
        selectedUserLimits >= currentUserLimits,
      requiresConfirmation: false,
    };
  };

  const renderMessage = (plan) => {
    const isInPlanHistory = isInCurrentPlanHistory(plan);
    if (purchaseHistoryData?.id && isInPlanHistory) {
      return "Cancel the request.";
    }

    const { isUpgradeable, requiresConfirmation } = isPlanUpgradeable(
      currentUserPackageData,
      plan
    );

    // if (isCurrentPackage(plan)) {
    if (isCurrentPackage(plan)) {
      const isPackageExpired = currentUserPackageData?.["isPackageExpired"];

      if (!isPackageExpired) {
        return "This is your current plan.";
      }
      if (!isUpgradeable) {
        return "This is your current plan. It has been updated to lower limits. You cannot reselect this plan.";
      }
      if (requiresConfirmation) {
        return "This is your current plan. You have exceeded the updated limits. If you reselect this plan, we’ll disable the extra users and assets.";
      }
      return "This is your current plan.";
    }

    if (!isUpgradeable) {
      return "This plan has lower limits than your current usage. Please disable the extra services (users, assets) first, then try again.";
    }

    // if (!isUpgradeable) {
    //   return "This plan has lower limits than your current usage. Please select a higher-tier plan.";
    // }

    return "You can upgrade this plan.";
  };

  const handleSelectPlan = (plan) => {
    setCurrentSelectedPlan(plan);
    const isInPlanHistory = isInCurrentPlanHistory(plan);

    if (purchaseHistoryData?.id && isInPlanHistory) {
      setCancelReqPopup(true);
      return;
    }

    if (purchaseHistoryData?.id && !isInPlanHistory) {
      setCancelCurrentReqPopup(true);
      return;
    }

    const { isUpgradeable, requiresConfirmation } = isPlanUpgradeable(
      currentUserPackageData,
      plan
    );

    if (!isUpgradeable) {
      setExtraServicePopup(true);
      return;
    }

    if (requiresConfirmation) {
      setShowPopup(true);
      return;
    }

    navigateToContactUs(plan);
  };

  const navigateToContactUs = (plan) => {
    const message = renderMessage(plan);

    navigate("/contact-us", {
      state: {
        currentPlan: myPackage[0],
        selectedPlan: plan,
        message: message,
        isCurrentPlan: isCurrentPackage(plan),
      },
    });
  };

  const isButtonDisabled = (plan, currentUserPackageData) => {
    // const { isUpgradeable } = isPlanUpgradeable(currentUserPackageData, plan);

    const isCurrent = isCurrentPackage(plan);
    if (isCurrent && isPackageExpired) return false;
    if (isCurrent && !isPackageExpired) return true;

    // if (!isCurrent && !isUpgradeable) return true;
    if (!isCurrent && currentUserPackageData?.package_id === plan.packageId)
      return true;

    return false;
  };

  const getButtonText = (plan) => {
    const isCurrent = isCurrentPackage(plan);
    const isInPlanHistory = isInCurrentPlanHistory(plan);

    if (purchaseHistoryData?.id && isInPlanHistory) {
      return "Cancel request";
    }

    if (isCurrent) {
      return isPackageExpired ? "Reselect Current Plan" : "Current Plan";
    }

    return "Select Plan";
  };

  const getButtonStyles = (plan, currentUserPackageData) => {
    const isDisabled = isButtonDisabled(plan, currentUserPackageData);

    return {
      backgroundColor: isDisabled ? "grey" : "black",
      color: isDisabled ? "darkgray" : "white",
      borderRadius: "20px",
    };
  };

  return (
    <>
      <Box sx={{ padding: "15px" }}>
        {userRole !== "Super Admin" && (
          <MyPackage
            isYearly={isYearly}
            setIsYearly={setIsYearly}
            myPackage={myPackage}
            renderValue={renderValue}
            shouldDisplayService={shouldDisplayService}
            currentUserPackageStatus={currentUserPackageData}
          />
        )}
        {(userRole === "Super Admin" ||
          (userIsAdmin === "Y" &&
            (userDepartment === 0 || !userDepartment))) && (
          <>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontSize: { xs: "1.3rem", sm: "2rem" },
                marginTop: { xs: "40px", sm: "60px" },
              }}
            >
              Package Plans
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Typography variant="body1" sx={{ mr: 2 }}>
                Monthly
              </Typography>
              <Switch
                checked={isYearly}
                onChange={() => setIsYearly(!isYearly)}
                color="primary"
              />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Yearly
              </Typography>
            </Box>
            <Grid container spacing={3} justifyContent="center">
              {isLoading ? (
                <>
                  {Array(3)
                    .fill(1)
                    .map((item, idx) => {
                      return (
                        <Grid item key={idx} xs={12} sm={6} md={4}>
                          <Card
                            className="package-card"
                            variant="outlined"
                            sx={{
                              borderRadius: "10px",
                              height: "100%",
                              transition: "0.3s",
                              "&:hover": {
                                boxShadow: 3,
                              },
                            }}
                          >
                            <CardContent sx={{ flexGrow: 1 }}>
                              <Typography
                                variant="h5"
                                align="center"
                                sx={{
                                  fontWeight: "bold",
                                  mb: 2,
                                  fontSize: "1.5rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width={150}
                                    height={50}
                                  />
                                </div>
                              </Typography>
                              <Typography
                                variant="h4"
                                align="center"
                                sx={{
                                  color: "primary.main",
                                  mb: 2,
                                  fontSize: "1.75rem",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Skeleton
                                    variant="text"
                                    width={70}
                                    height={50}
                                  />
                                </div>
                              </Typography>
                              <List>
                                {Array(3)
                                  .fill(1)
                                  .map((item, idx) => {
                                    return (
                                      <React.Fragment key={idx}>
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={30}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width={250}
                                          height={30}
                                        />
                                        <Skeleton
                                          variant="text"
                                          width={150}
                                          height={30}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                              </List>
                            </CardContent>
                          </Card>
                        </Grid>
                      );
                    })}
                </>
              ) : (
                packageServiceData.map((plan, index) => (
                  <Grid
                    item
                    key={plan.packageId}
                    xs={12}
                    sm={6}
                    md={6}
                    xl={4}
                    ref={(el) => (cardRefs.current[index] = el)}
                  >
                    <Card
                      className={`package-card ${
                        selectedPlan?.package_id === plan.packageId
                          ? "selected"
                          : ""
                      }`}
                      variant="outlined"
                    >
                      <CardContent sx={{ flexGrow: 1, padding: "0" }}>
                        <div className="heading text-center">
                          <h3 className="package-title text-uppercase fw-medium m-0">
                            {plan.packageName}
                          </h3>
                          <p className="price-txt m-0">
                            $
                            {isYearly
                              ? parseFloat(plan?.price_annually)
                              : parseFloat(plan?.price_monthly)}
                            <small className="year">
                              {isYearly ? "/yr" : "/mo"}
                            </small>
                            {isYearly && plan?.price_monthly && (
                              <span className="old-price" variant="body2">
                                <span className="line-through">
                                  {" "}
                                  ${parseFloat(plan?.price_monthly) * 12}
                                </span>
                                <span className="year">/yr</span>
                              </span>
                            )}
                          </p>
                        </div>
                        <List className="ul-list">
                          {plan.packageServiceData
                            .filter(shouldDisplayService)
                            .map((service) => {
                              return (
                                <>
                                  <ListItem
                                    key={service.serviceId}
                                    disablePadding
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: "auto",
                                        marginRight: { xs: "8px", sm: "10px" },
                                      }}
                                    >
                                      {service.value === "true" ? (
                                        <CheckCircleIcon
                                          className="check-sign"
                                          sx={{
                                            color: "primary.main",
                                            fontSize: "1.5rem",
                                          }}
                                        />
                                      ) : service.value === "false" ? (
                                        <CancelIcon
                                          className="cross-sign"
                                          sx={{
                                            color: "error.main",
                                            fontSize: "1.5rem",
                                          }}
                                        />
                                      ) : (
                                        <CheckCircleIcon
                                          className="check-sign"
                                          sx={{
                                            color: "primary.main",
                                            fontSize: "1.5rem",
                                          }}
                                        />
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <p className="m-0" variant="body2">
                                          <strong>
                                            {service.serviceName}:{" "}
                                          </strong>
                                          <span
                                            style={{
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {renderValue(service, plan)}
                                          </span>
                                        </p>
                                      }
                                      primaryTypographyProps={{ align: "left" }}
                                    />
                                  </ListItem>
                                </>
                              );
                            })}
                        </List>
                      </CardContent>

                      <div className="package-footer">
                        {userRole !== "Super Admin" && (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{renderMessage(plan)}</Tooltip>}
                          >
                            <span>
                              <button
                                className="package-button btn btn-dark w-100"
                                variant="contained"
                                onClick={() => handleSelectPlan(plan)}
                                disabled={isButtonDisabled(
                                  plan,
                                  currentUserPackageData
                                )}
                                style={getButtonStyles(
                                  plan,
                                  currentUserPackageData
                                )}
                              >
                                {getButtonText(plan)}
                              </button>
                            </span>
                          </OverlayTrigger>
                        )}
                        {/* {userRole !== "Super Admin" ? (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{renderMessage(plan)}</Tooltip>}
                          >
                            <span>
                              <button
                                className="package-button btn btn-dark w-100"
                                variant="contained"
                                onClick={() => handleSelectPlan(plan)}
                                disabled={isButtonDisabled(
                                  plan,
                                  currentUserPackageData
                                )}
                                style={getButtonStyles(
                                  plan,
                                  currentUserPackageData
                                )}
                              >
                                {getButtonText(plan)}
                              </button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <button
                            className="package-button btn btn-dark w-100"
                            variant="contained"
                            onClick={() => handleSelectPlan(plan)}
                            disabled={
                              isCurrentPackage(plan) &&
                              !currentUserPackageData?.isPackageExpired
                            }
                            style={{
                              backgroundColor:
                                isCurrentPackage(plan) &&
                                !currentUserPackageData?.isPackageExpired
                                  ? "grey"
                                  : "black",
                              color:
                                isCurrentPackage(plan) &&
                                !currentUserPackageData?.isPackageExpired
                                  ? "darkgray"
                                  : "white",
                              borderRadius: "20px",
                            }}
                          >
                            {isCurrentPackage(plan)
                              ? currentUserPackageData?.isPackageExpired
                                ? "Reselect Current Plan"
                                : "Current Plan"
                              : "Select Plan"}
                          </button>
                        )} */}
                      </div>
                    </Card>
                  </Grid>
                ))
              )}

              <Grid item xs={12} sm={6} md={6} xl={4}>
                <Card className="package-card" variant="outlined">
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="heading text-center">
                      <h3 className="package-title text-uppercase fw-medium m-0">
                        Custom Package
                      </h3>
                    </div>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        fontSize: "1.2rem",
                        display: "flex",
                        justifyContent: "center",
                        margin: { xs: "70px 0", sm: "150px 0" },
                      }}
                    >
                      Request a Call for Custom Package
                    </Typography>
                  </CardContent>

                  <Box sx={{ p: 2, mt: "auto" }}>
                    <Link to="/contact-us" style={{ textDecoration: "none" }}>
                      <button
                        className="package-button btn btn-dark w-100"
                        variant="contained"
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "20px",
                          px: 4,
                          "&:hover": {
                            backgroundColor: "darkgray",
                          },
                        }}
                      >
                        Contact Us
                      </button>
                    </Link>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      <Modal open={showPopup} onClose={handlePopupClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 400 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            You’ve exceeded the updated limits!
          </Typography>
          <Typography variant="body1" gutterBottom>
            You’ve already used the earlier limits for users or assets. If you
            reselect this plan, we’ll disable the extra users and assets.
          </Typography>
          <Box
            mt={3}
            className="d-flex align-items-center"
            display="flex"
            justifyContent="space-between"
            gap={2}
          >
            <Button className="btn-primary ms-2" onClick={handlePopupClose}>
              Go Back
            </Button>
            <Button className="btn-dark" type="submit" onClick={handleContinue}>
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={cancelReqPopup} onClose={handleCancelReqPopupClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 400 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Cancel Active Request?
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Are you sure you want to cancel the current active request?
          </Typography>
          <Box
            mt={3}
            className="d-flex align-items-center"
            display="flex"
            justifyContent="space-between"
            gap={2}
          >
            <Button
              className="btn-primary ms-2"
              onClick={handleCancelReqPopupClose}
            >
              Go Back
            </Button>
            <Button
              className="btn-dark"
              type="submit"
              onClick={handleCancelReqPopupContinue}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={cancelCurrentReqPopup}
        onClose={handleCancelCurrentReqPopupClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 400 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Active Request Detected
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            You already have an active request. If you proceed, your previous
            request will be canceled.
          </Typography>
          <Box
            mt={3}
            className="d-flex align-items-center"
            display="flex"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              className="btn-primary ms-2"
              onClick={handleCancelCurrentReqPopupClose}
            >
              Keep Previous Request
            </Button>
            <Button
              className="btn-dark"
              type="submit"
              onClick={handleCancelCurrentReqPopupContinue}
            >
              Proceed Anyway
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={extraServicePopup} onClose={handleExtraServicePopupClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 400 },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Extra Services Detected
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            This plan has lower limits than your current usage. Please disable
            the extra services (users, assets) before proceeding.
          </Typography>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleExtraServicePopupClose}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AllPackage;
