import React, { useEffect, useState } from "react";
import { getInvoices, updateInvoiceStatus } from "../../Api/InvoiceApi";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { FaEye } from "react-icons/fa";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";
import { useSelector } from "react-redux";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import FileOpenIcon from "@mui/icons-material/FileOpen";

const getStatusColor = (status) => {
  switch (status) {
    case "paid":
      return "#4caf50"; // Green
    case "pending":
      return "#ffc107"; // Yellow
    case "cancelled":
      return "#f44336"; // Red
    case "failed":
      return "#9e9e9e"; // Gray
    default:
      return "#ddd"; // Default color
  }
};

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // DATE TIME FORMAT
  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );
  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;
  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;
  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const fetchInvoices = async () => {
    try {
      const response = await getInvoices();
      setInvoices(response);
    } catch (err) {
      console.error("Failed to fetch invoices", err);
      setError("Error fetching invoices. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const handleExpandRow = (invoiceId) => {
    setExpandedRow(expandedRow === invoiceId ? null : invoiceId);
  };

  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      // Update the invoice status locally first
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice.invoice_id === invoiceId
            ? { ...invoice, status: newStatus }
            : invoice
        )
      );

      // Await the API call and handle success
      const res = await updateInvoiceStatus(invoiceId, newStatus);
      ToastMessage.Success(res.message);
    } catch (error) {
      // Handle any error from the API call
      console.error("Error updating invoice status:", error);
      ToastMessage.Error("Failed to update the invoice status.");
    }
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Card sx={{ boxShadow: 3, borderRadius: 2, p: 2 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Invoice List
          </Typography>

          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <CircularProgress color="primary" size={50} />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell>
                        <strong>Invoice ID</strong>
                      </TableCell>
                      <TableCell>
                        <strong>User Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Total Amount</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>From</strong>
                      </TableCell>
                      <TableCell>
                        <strong>To</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Details</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <React.Fragment key={row.invoice_id}>
                          {/* Main Row */}
                          <TableRow>
                            <TableCell>{row?.invoice_id || "N/A"}</TableCell>
                            <TableCell>
                              {row?.user?.user_name || "N/A"}
                            </TableCell>
                            <TableCell>${row?.total_amount || "-"}</TableCell>
                            <TableCell>
                              <Select
                                value={row.status}
                                onChange={(e) =>
                                  handleStatusChange(
                                    row.invoice_id,
                                    e.target.value
                                  )
                                }
                                size="small"
                                sx={{
                                  minWidth: 120,
                                  height: 32, // Small height for better UI
                                  backgroundColor: getStatusColor(row.status),
                                  color:
                                    row.status === "Paid" ? "#fff" : "#333",
                                  fontWeight: "bold",
                                  borderRadius: "20px",
                                  padding: "4px 12px",
                                  fontSize: "0.85rem",
                                }}
                              >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="paid">Paid</MenuItem>
                                <MenuItem value="cancelled">Cancelled</MenuItem>
                                <MenuItem value="failed">Failed</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell>
                              {formatDateTime(
                                row?.start_date,
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedDateFinal || "N/A"}
                            </TableCell>
                            <TableCell>
                              {formatDateTime(
                                row?.end_date,
                                dateFormat,
                                timeZone,
                                timeFormat
                              ).timeZone.formattedDateFinal || "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                color="primary"
                                onClick={() => handleExpandRow(row.invoice_id)}
                              >
                                <FaEye />
                              </IconButton>
                              {row?.file_name && (
                                <IconButton
                                  color="primary"
                                  aria-label="Open invoice file"
                                >
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${row?.file_name}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    title="View Invoice PDF"
                                  >
                                    <FileOpenIcon />
                                  </a>
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>

                          {/* Expandable Details Row */}
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              sx={{ paddingBottom: 0, paddingTop: 0 }}
                            >
                              <Collapse
                                in={expandedRow === row.invoice_id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Card sx={{ margin: 2, p: 2, boxShadow: 2 }}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    color="primary"
                                    gutterBottom
                                  >
                                    Invoice Details (Invoice ID:{" "}
                                    {row.invoice_id})
                                  </Typography>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow
                                        sx={{ backgroundColor: "#f9f9f9" }}
                                      >
                                        <TableCell>
                                          <strong>Sr. No.</strong>
                                        </TableCell>
                                        <TableCell>
                                          <strong>Service</strong>
                                        </TableCell>
                                        <TableCell>
                                          <strong>Unit Used</strong>
                                        </TableCell>
                                        <TableCell>
                                          <strong>Unit Price ($)</strong>
                                        </TableCell>
                                        <TableCell>
                                          <strong>Price ($)</strong>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {row.invoiceDetails?.map(
                                        (detail, index) => (
                                          <TableRow key={detail.id}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              {detail?.service?.service_name ||
                                                "N/A"}
                                            </TableCell>
                                            <TableCell>
                                              {detail.unit_used}
                                            </TableCell>
                                            <TableCell>
                                              ${detail.unit_price}
                                            </TableCell>
                                            <TableCell>
                                              $
                                              {detail.unit_price *
                                                detail.unit_used}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                      {/* Total Row */}
                                      <TableRow
                                        sx={{
                                          backgroundColor: "#f0f0f0",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <TableCell colSpan={4} align="right">
                                          <strong>Total:</strong>
                                        </TableCell>
                                        <TableCell>
                                          <strong>
                                            ${row?.total_amount || "0.00"}
                                          </strong>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Card>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={invoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default InvoiceList;
