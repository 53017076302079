import {
  ADD_POWERPANEL_SITES_ID,
  FETCH_API_DATA_FAILURE,
  FETCH_API_DATA_REQUEST,
  FETCH_API_DATA_SUCCESS,
  FETCH_DEVELOPERS_FAILURE,
  FETCH_DEVELOPERS_REQUEST,
  FETCH_DEVELOPERS_SUCCESS,
  FETCH_PERFORMANCE_REQUEST,
  FETCH_WEBSITE_SECURITY_REQUEST,
} from "./actionType";

const initialState = {
  sitesData: [],
  developerData: [],
  loading: false,
  error: null,
  apiData: [],
  apiDataError: null,
  apiDataLoading: false,
  websiteSecurityLoader: [],
  performanceLoader: [],
};

const savePowerPanelSitesIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POWERPANEL_SITES_ID:
      return {
        ...state,
        sitesData: action.payload,
      };
    case FETCH_DEVELOPERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DEVELOPERS_SUCCESS:
      return {
        ...state,
        loading: false,
        developerData: action.payload,
      };
    case FETCH_DEVELOPERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_API_DATA_REQUEST:
      return {
        ...state,
        apiDataLoading: true,
        apiLoadingAsset: action.payload,
        apiDataError: null,
      };
    case FETCH_API_DATA_SUCCESS:
      return {
        ...state,
        apiDataLoading: false,
        apiData: action.payload,
      };
    case FETCH_API_DATA_FAILURE:
      return {
        ...state,
        apiDataLoading: false,
        apiDataError: action.payload,
      };
    case FETCH_WEBSITE_SECURITY_REQUEST:
      return {
        ...state,
        websiteSecurityLoader: action.payload,
      };
    case FETCH_PERFORMANCE_REQUEST:
      return {
        ...state,
        performanceLoader: action.payload,
      };
    default:
      return state;
  }
};

export default savePowerPanelSitesIdReducer;
