import { Box } from "@mui/material";
import React from "react";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Spinner } from "reactstrap";

const RunScan = ({ loading, onClick, disabled = false }) => {
  return (
    <>
      <Card className="main-card">
        <Card.Body className="nv_tab_com">
          <Box className="text-center">
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={
                <Tooltip id={`tooltip-view`}>
                  {disabled
                    ? "You have already used your limit for this month. To continue, please increase your limit in the User Settings page."
                    : "Run Scan"}
                </Tooltip>
              }
            >
              <div style={{ display: "inline-block", width: "100%" }}>
                <Button
                  // title="Run Scan"
                  onClick={onClick}
                  style={{ width: "100%", marginTop: "20px" }}
                  disabled={disabled}
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Running Scan...
                    </>
                  ) : (
                    "Run Scan"
                  )}
                </Button>
              </div>
            </OverlayTrigger>
          </Box>
          {loading && (
            <Box
              className="alert alert-borderless alert-warning text-center mb-2 mx-2 mt-4"
              role="alert"
            >
              Please wait sometimes as we are running a scan
            </Box>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RunScan;
