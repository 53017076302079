import React, { useEffect, useRef, useState } from "react";
import {
  getAllServices,
  getUserServices,
  addUpdateUserService,
  getCurrentUsageService,
  generateInvoice,
  generateInvoiceReport,
} from "../../Api/UserServicesSettingsApi";
import {
  Checkbox,
  Button,
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  Card,
  CardContent,
  useTheme,
  FormControlLabel,
  Switch,
  TextField,
  Grid,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { getUserId } from "../../Api/common";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { getAnalyticsServices } from "../../helpers/Help/CommomApi";
import { decryptResponse } from "../../Components/Common/common";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import LoopIcon from "@mui/icons-material/Loop";
import ReceiptIcon from "@mui/icons-material/Receipt";
import moment from "moment";
import { Spinner } from "reactstrap";

const uptimeUnitOptions = [
  { value: "Minutes", label: "Minutes" },
  { value: "Hours", label: "Hours" },
];

const dayMonthLimitOptions = [
  { value: "Month", label: "Month" },
  { value: "Day", label: "Day" },
];

const MenuHeight = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const UserServicesSettings = () => {
  const theme = useTheme();
  const userId = getUserId();
  const location = useLocation();
  const flatpickrRef = useRef(null);

  const { rowData } = location.state || {};
  const adminId = rowData?.id;

  const userData = useSelector((state) => state.userDetails.userData);
  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );

  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const [services, setServices] = useState([]);
  const [currentUsage, setCurrentUsage] = useState([]);
  const [analyticsServices, setAnalyticsServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState([
    moment().startOf("month").toDate(),
    moment().toDate(),
  ]);
  const [userPreferences, setUserPreferences] = useState({});
  const [loading, setLoading] = useState(true);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  let [startDate, endDate] = selectedDate;

  if (startDate) {
    startDate = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate) {
    endDate = moment(endDate).format("YYYY-MM-DD");
  }

  useEffect(() => {
    if (adminId) {
      fetchCurrentUsage(adminId);
      fetchData(adminId);
    } else {
      fetchCurrentUsage(userId);
      fetchData(userId);
    }
  }, [adminId, userId, startDate, endDate]);

  // Fetch services and user preferences
  const fetchData = async (id) => {
    try {
      const [servicesResponse, userServicesResponse] = await Promise.all([
        getAllServices(),
        getUserServices(id),
      ]);

      setServices(servicesResponse);
      const preferences = userServicesResponse.reduce((acc, cur) => {
        acc[cur.service_id] = {
          enabled: cur.is_enabled || false,
          settings: cur.settings || {},
          price: cur.price || 0,
        };
        return acc;
      }, {});
      setUserPreferences(preferences);
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch current usage of services
  const fetchCurrentUsage = async (id) => {
    try {
      const response = await getCurrentUsageService(id, startDate, endDate);
      setCurrentUsage(response);
    } catch (error) {
      console.error("Error fetching current usage:", error);
    }
  };

  const fetchAnalyticsServices = async () => {
    try {
      const response = await getAnalyticsServices();
      const responseData = decryptResponse(response?.data);
      if (responseData) {
        setAnalyticsServices(responseData);
      }
    } catch (error) {
      console.error("Error fetching analytics services:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsServices();
  }, []);

  const handlePreferenceChange = (serviceId) => {
    setUserPreferences((prev) => ({
      ...prev,
      [serviceId]: {
        ...prev[serviceId],
        enabled: !prev[serviceId]?.enabled,
      },
    }));
  };

  const handleSavePreferences = async () => {
    const servicePreferences = services.map((service) => ({
      service_id: service.service_id,
      preference: {
        enabled: !!userPreferences[service.service_id]?.enabled,
        settings: userPreferences[service.service_id]?.settings || {},
        price: userPreferences[service.service_id]?.price,
      },
    }));

    try {
      await addUpdateUserService(adminId || userId, servicePreferences);
      ToastMessage.Success("Preferences saved successfully!");
    } catch (error) {
      ToastMessage.Error("Failed to save preferences!");
      console.error("Error saving preferences:", error);
    }
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates[1] !== undefined) {
      setSelectedDate(selectedDates);
    }
  };

  const handleGenerateReport = async () => {
    setInvoiceLoading(true);
    try {
      if (adminId) {
        await generateInvoiceReport(adminId, startDate, endDate);
      } else {
        await generateInvoiceReport(userId, startDate, endDate);
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const handleGenerateInvoice = async () => {
    setInvoiceLoading(true);
    try {
      if (adminId) {
        await generateInvoice(adminId, startDate, endDate);
      } else {
        await generateInvoice(userId, startDate, endDate);
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    } finally {
      setInvoiceLoading(false);
    }
  };

  const handleReset = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.clear();
    }

    setSelectedDate([moment().startOf("month").toDate(), moment().toDate()]);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      p={4}
      display="flex"
      flexDirection="column"
      gap={4}
      maxWidth={1000}
      margin="auto"
    >
      {/* Service Settings Section */}
      <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Manage Your Service Settings
          </Typography>
          <Box mb={3}>
            {services.map((service) => (
              <Box
                key={service.service_id}
                display="flex"
                flexDirection="column"
                borderBottom="1px solid #e0e0e0"
                p={2}
                sx={{
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ flex: 1 }}>
                    {service.service_name}
                  </Typography>

                  {userData?.roles?.isSuperAdmin === "Y" && (
                    <TextField
                      type="number"
                      label="Price ($)"
                      value={userPreferences[service.service_id]?.price || 0}
                      onChange={(e) => {
                        const price = parseFloat(e.target.value) || 0;
                        setUserPreferences((prev) => ({
                          ...prev,
                          [service.service_id]: {
                            ...prev[service.service_id],
                            price,
                            settings: prev[service.service_id]?.settings || {},
                          },
                        }));
                      }}
                      sx={{
                        width: "120px",
                        marginRight: 2,
                      }}
                    />
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        checked={!!userPreferences[service.service_id]?.enabled}
                        onChange={() =>
                          handlePreferenceChange(service.service_id)
                        }
                        color="primary"
                      />
                    }
                    label=""
                    sx={{
                      marginRight: 0,
                    }}
                  />
                </Box>

                {service.slug === "analytics_reports" &&
                  !!userPreferences[service.service_id]?.enabled && (
                    <Box mt={1}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          style={{
                            opacity: userPreferences[service.service_id]
                              ?.enabled
                              ? 1
                              : 0.5, // Adjust opacity
                          }}
                        >
                          <InputLabel>
                            Analytics Services
                            {userPreferences[service.service_id]?.enabled
                              ? " *"
                              : ""}
                          </InputLabel>
                          <Select
                            label="Analytics Services"
                            name="analytics_services"
                            multiple
                            MenuProps={MenuHeight}
                            value={
                              userPreferences[service.service_id]?.settings
                                ?.analytics_services || []
                            }
                            onChange={(event) => {
                              const selectedValues = event.target.value;
                              setUserPreferences((prev) => ({
                                ...prev,
                                [service.service_id]: {
                                  ...prev[service.service_id],
                                  settings: {
                                    ...prev[service.service_id]?.settings,
                                    analytics_services: selectedValues,
                                  },
                                },
                              }));
                            }}
                            renderValue={(selected) =>
                              analyticsServices
                                .filter((service) =>
                                  selected.includes(service.s_service_id)
                                )
                                .map((service) => service.s_service_name)
                                .join(", ")
                            }
                            disabled={
                              !userPreferences[service.service_id]?.enabled
                            }
                          >
                            {analyticsServices?.map((item) => (
                              <MenuItem
                                key={item?.s_service_id}
                                value={item?.s_service_id}
                              >
                                <Checkbox
                                  checked={userPreferences[
                                    service.service_id
                                  ]?.settings?.analytics_services?.includes(
                                    item.s_service_id
                                  )}
                                />
                                <ListItemText primary={item?.s_service_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Box>
                  )}

                {service.slug === "uptime_report" &&
                  !!userPreferences[service.service_id]?.enabled && (
                    <Box mt={1}>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                          {/* Monitoring Interval Input */}
                          <Grid item xs={6}>
                            <TextField
                              type="number"
                              variant="outlined"
                              name="uptime_monitoring_interval"
                              fullWidth
                              label={`Monitoring interval${
                                userPreferences[service.service_id]?.enabled
                                  ? ""
                                  : " *"
                              }`}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              value={
                                userPreferences[service.service_id]?.settings
                                  ?.uptime_interval || ""
                              }
                              onChange={(e) =>
                                setUserPreferences((prev) => ({
                                  ...prev,
                                  [service.service_id]: {
                                    ...prev[service.service_id],
                                    settings: {
                                      ...prev[service.service_id]?.settings,
                                      uptime_interval: e.target.value,
                                    },
                                  },
                                }))
                              }
                              style={{
                                opacity: userPreferences[service.service_id]
                                  ?.enabled
                                  ? 1
                                  : 0.5, // Adjust opacity
                              }}
                            />
                          </Grid>

                          {/* Interval Unit Select */}
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Interval Unit</InputLabel>
                              <Select
                                label="Interval Unit"
                                name="uptime_monitoring_unit"
                                value={
                                  userPreferences[service.service_id]?.settings
                                    ?.uptime_interval_unit || ""
                                }
                                onChange={(e) =>
                                  setUserPreferences((prev) => ({
                                    ...prev,
                                    [service.service_id]: {
                                      ...prev[service.service_id],
                                      settings: {
                                        ...prev[service.service_id]?.settings,
                                        uptime_interval_unit: e.target.value,
                                      },
                                    },
                                  }))
                                }
                                style={{
                                  opacity: userPreferences[service.service_id]
                                    ?.enabled
                                    ? 1
                                    : 0.5, // Adjust opacity
                                }}
                              >
                                {uptimeUnitOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                {service.slug === "performance_report" &&
                  !!userPreferences[service.service_id]?.enabled && (
                    <Box mt={1}>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              type="number"
                              variant="outlined"
                              name="performance limit"
                              fullWidth
                              label="Performance Limit"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              value={
                                userPreferences[service.service_id]?.settings
                                  ?.performance_limit || ""
                              }
                              onChange={(e) => {
                                setUserPreferences((prev) => ({
                                  ...prev,
                                  [service.service_id]: {
                                    ...prev[service.service_id],
                                    settings: {
                                      ...prev[service.service_id]?.settings,
                                      performance_limit: e.target.value,
                                    },
                                  },
                                }));
                              }}
                              style={{
                                opacity: userPreferences[service.service_id]
                                  ?.enabled
                                  ? 1
                                  : 0.5,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Performance Limit Unit</InputLabel>
                              <Select
                                label="Performance Limit Unit"
                                name="performance_limit_unit"
                                value={
                                  userPreferences[service.service_id]?.settings
                                    ?.performance_limit_unit || ""
                                }
                                onChange={(e) => {
                                  setUserPreferences((prev) => ({
                                    ...prev,
                                    [service.service_id]: {
                                      ...prev[service.service_id],
                                      settings: {
                                        ...prev[service.service_id]?.settings,
                                        performance_limit_unit: e.target.value,
                                      },
                                    },
                                  }));
                                }}
                                style={{
                                  opacity: userPreferences[service.service_id]
                                    ?.enabled
                                    ? 1
                                    : 0.5,
                                }}
                              >
                                {dayMonthLimitOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                {service.slug === "security_scan" &&
                  !!userPreferences[service.service_id]?.enabled && (
                    <Box mt={1}>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              type="number"
                              variant="outlined"
                              name="security limit"
                              fullWidth
                              label="Security Limit"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              value={
                                userPreferences[service.service_id]?.settings
                                  ?.security_limit || ""
                              }
                              onChange={(e) => {
                                setUserPreferences((prev) => ({
                                  ...prev,
                                  [service.service_id]: {
                                    ...prev[service.service_id],
                                    settings: {
                                      ...prev[service.service_id]?.settings,
                                      security_limit: e.target.value,
                                    },
                                  },
                                }));
                              }}
                              style={{
                                opacity: userPreferences[service.service_id]
                                  ?.enabled
                                  ? 1
                                  : 0.5,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Security Limit Unit</InputLabel>
                              <Select
                                label="Security Limit Unit"
                                name="security_limit_unit"
                                value={
                                  userPreferences[service.service_id]?.settings
                                    ?.security_limit_unit || ""
                                }
                                onChange={(e) => {
                                  setUserPreferences((prev) => ({
                                    ...prev,
                                    [service.service_id]: {
                                      ...prev[service.service_id],
                                      settings: {
                                        ...prev[service.service_id]?.settings,
                                        security_limit_unit: e.target.value,
                                      },
                                    },
                                  }));
                                }}
                                style={{
                                  opacity: userPreferences[service.service_id]
                                    ?.enabled
                                    ? 1
                                    : 0.5,
                                }}
                              >
                                {dayMonthLimitOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSavePreferences}
            sx={{
              textTransform: "none",
              borderRadius: 2,
              boxShadow: 2,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Save Preferences
          </Button>
        </CardContent>
      </Card>

      {/* Current Usage Section */}
      <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            borderBottom="1px solid #e0e0e0"
            p={2}
            sx={{
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={1}
              sx={{
                transition: "background-color 0.3s",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <Typography variant="h5">My Current Usage</Typography>

              <Box display="flex" justifyContent="space-between">
                <Flatpickr
                  placeholder="Select Date Range"
                  ref={flatpickrRef}
                  className="form-control date-box"
                  options={{
                    dateFormat: `${dateFormat}`,
                    mode: "range",
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <Button
                  sx={{ marginLeft: "10px" }}
                  className="btn btn-primary reset-btn"
                  onClick={handleReset}
                >
                  <LoopIcon />
                </Button>
              </Box>
            </Box>
          </Box>

          <List>
            {currentUsage.map((service) => (
              <React.Fragment key={service.service_id}>
                <ListItem
                  sx={{
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <ListItemText
                    primary={service.service_name}
                    secondary={
                      service.totalHitCount
                        ? `Total Hits: ${service.totalHitCount}`
                        : "No usage yet"
                    }
                    primaryTypographyProps={{ fontWeight: 500 }}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}

            {/* Centering the Generate Invoice Button */}
            <Box display="flex" justifyContent="center" mt={3}>
              <Button
                sx={{ padding: "10px 20px" }}
                className="btn btn-primary reset-btn"
                disabled={invoiceLoading}
                onClick={handleGenerateReport}
              >
                {invoiceLoading ? (
                  <>
                    <Spinner size="sm" variant="light" /> Generating{" "}
                  </>
                ) : (
                  <>
                    <ReceiptIcon /> Generate Report
                  </>
                )}
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                className="btn btn-primary reset-btn"
                disabled={invoiceLoading}
                onClick={handleGenerateInvoice}
              >
                {invoiceLoading ? (
                  <>
                    <Spinner size="sm" variant="light" /> Generating{" "}
                  </>
                ) : (
                  <>
                    <ReceiptIcon /> Generate Invoice
                  </>
                )}
              </Button>
            </Box>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserServicesSettings;
