import axios from "axios";
import { decryptResponse } from "../Components/Common/common";
import { GET_INVOICES, UPDATE_INVOICE_STATUS } from "./ApiRoutes";

export const getInvoices = async () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_INVOICES)
      .then((response) => {
        const decryptedResponse = decryptResponse(response?.data);
        resolve(decryptedResponse);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateInvoiceStatus = async (invoiceId, status) => {
  return new Promise((resolve, reject) => {
    axios
      .post(UPDATE_INVOICE_STATUS, { invoiceId, status })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
