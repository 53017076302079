import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "./InternalUsers.css";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import ProfileImage from "../../../Components/Common/ProfileImage";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import noSelectedImage from "../../../assets/images/users/user-dummy-img.jpg";
import {
  DeleteDepartment,
  changeInternalUserStatusReq,
  updateInternalUsersReq,
} from "../../../Api/Users/InternalUsers/InternalUsersReq";
import {
  DeleteDepartmentRes,
  changeInternalUserStatusRes,
  getInternalUsersRes,
  updateInternalUsersRes,
} from "../../../Api/Users/InternalUsers/InternalUsersRes";
import _ from "lodash";
import { USERS } from "../../../Components/Common/ModuleName";
import { useSelector } from "react-redux";
import InternalUsersModal from "./InternalUsersModal";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { DeleteIcon } from "../../../Components/Common/SvgIcons";
import { CustomTableStyle } from "../../../Components/Common/constants";
import LoopIcon from "@mui/icons-material/Loop";
import { AutoDropdownStyle } from "../../NewDashboard/constant";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { socket } from "../../../Components/Common/socket";
import common, { convertMBToBytes } from "../../../Components/Common/common";
import {
  getAllPackageList,
  getInternalUsersData,
  // getPackagesServices,
  // getUserPackageLimit,
} from "../../../helpers/Help/CommomApi";
import { useDefaultImageSize } from "../../../Components/Hooks/UseDeafultImageSizeHook";
import { getAuthToken, getUserId } from "../../../Api/common";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { getCurrentUsageService } from "../../../Api/UserServicesSettingsApi";

const InternalUsers = () => {
  const token = getAuthToken();
  const user_id = getUserId();

  const DEFAULT_IMAGE_SIZE = useDefaultImageSize();

  const navigate = useNavigate();

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );
  const userData = useSelector((state) => state.userDetails.userData);
  const hasPermission = permissionData?.find(
    (item) => item.module_name === USERS
  );
  // DATE TIME FORMAT

  const userIsAdmin = userData?.isAdmin;
  const userDepartment = userData?.department_id;

  const [query, setQuery] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [roleType, setRoleType] = useState([]);
  const [roleSelectOpt, setRoleSelectOpt] = useState(null);
  const [adminSelectOpt, setAdminSelectOpt] = useState(null);
  const [departmentSelectOpt, setDepartmentSelectOpt] = useState(null);
  const [websiteCategory, setWebsiteCategory] = useState([]);
  const [statusSelectOpt, setStatusSelectOpt] = useState({
    value: "Active",
    label: "Active",
  });
  const [selectAllWebsites, setSelectAllWebsites] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [checkboxes, setCheckboxes] = useState({
    web_security_read: false,
    web_security_execute: false,
    notes_read: false,
    notes_execute: false,
  });
  const [postIsLoading, setPostIsLoading] = useState(false);
  const [departmentStatus, setDepartmentStatus] = useState("");
  // const [myPackage, setMyPackage] = useState([]);
  // const [myUsersLimit, setMyUsersLimit] = useState([]);
  const [allPackage, setAllPackage] = useState([]);
  const [packageSelectOpt, setPackageSelectOpt] = useState(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState("");
  const [countryViceNumberLength, setCountryViceNumberLength] = useState(0);
  const [selectedPriceType, setSelectedPriceType] = useState("monthly");
  const [isUsersEnabled, setIsUsersEnabled] = useState(true);

  const handleShow = () => setShow(true);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: null,
      fullName: "",
      roleSelect: "",
      departmentSelect: null,
      asset_url_management: null,
      email: "",
      password: "",
      confirmPassword: "",
      hiddenPassword: hiddenPassword,
      phone: "",
      country: "",
      statusOption: "Active",
      departmentImage: null,
      adminSelectOpt: null,
      packageSelectOpt: null,
      selectedPriceType: "monthly",
    },

    validationSchema: Yup.object({
      fullName: Yup.string()
        .trim()
        .max(60)
        .required("Please enter the full name."),
      roleSelect: Yup.string().max(255).required("Please select role."),
      departmentSelect: Yup.mixed().when("roleSelect", {
        is: (val) => val !== "Super Admin" && val !== "Admin",
        then: () =>
          Yup.mixed()
            .test(
              "is-number-or-empty",
              "Please select a department",
              (value) =>
                value === "" ||
                (Number.isInteger(Number(value)) && Number(value) > 0)
            )
            .required("Please select department."),
        otherwise: () => Yup.mixed().nullable(),
      }),
      asset_url_management: Yup.array().nullable(),
      email: Yup.string()
        .email("Must be a valid email.")
        .max(70)
        .required("Please enter the Email."),
      password: Yup.string()
        .test("password-condition", function (value) {
          if (this.parent.hiddenPassword && !value) {
            return true; // Skip validation if hiddenPassword exists
          }

          if (!value) {
            return this.createError({ message: "Password is required" });
          }
          if (value.length < 8) {
            return this.createError({
              message: "Password must be at least 8 characters",
            });
          }
          if (!/[a-z]/.test(value)) {
            return this.createError({
              message: "Password must include at least one lowercase letter",
            });
          }
          if (!/[A-Z]/.test(value)) {
            return this.createError({
              message: "Password must include at least one uppercase letter",
            });
          }
          if (!/[0-9]/.test(value)) {
            return this.createError({
              message: "Password must include at least one number",
            });
          }
          return true;
        })
        .trim(),

      confirmPassword: Yup.string()
        .test("repassword-condition", function (value) {
          if (this.parent.hiddenPassword && !this.parent.password) {
            return true; // Skip validation if hiddenPassword exists
          }

          if (!value) {
            return this.createError({
              message: "Confirm Password is required.",
            });
          }
          if (this.parent.password !== value) {
            return this.createError({
              message: "Passwords do not match",
            });
          }
          return true;
        })
        .trim(),
      phone: Yup.string()
        .nullable()
        .test("length", null, function (value) {
          const { path, createError } = this;
          if (value && value.length !== countryViceNumberLength) {
            return createError({
              path,
              message: `Phone number must be exactly ${countryViceNumberLength} digits`,
            });
          }
          return true;
        })
        .matches(
          new RegExp(`^\\d{${countryViceNumberLength}}$`),
          `Please enter a valid phone number with exactly ${countryViceNumberLength} digits`
        )
        .test(
          "not-all-zeros",
          "Phone number cannot consist of all zeros",
          function (value) {
            const { path, createError } = this;
            if (value && /^0+$/.test(value)) {
              return createError({
                path,
                message: "Phone number cannot consist of all zeros",
              });
            }
            return true;
          }
        ),
      country: Yup.string().nullable().optional(),
      statusOption: Yup.string().max(255).required("Please select the status."),

      packageSelectOpt: Yup.mixed()
        .nullable()
        .test(
          "package-select-validate",
          "Please select a package.",
          function (value) {
            const { roleSelect } = this.parent;
            // If roleSelect is "Admin", packageSelectOpt is required
            if (
              userData?.role === "Super Admin" &&
              roleSelect === "Admin" &&
              !value
            ) {
              return false; // Will trigger the error message
            }
            return true;
          }
        ),

      // Conditional validation for adminSelectOpt
      adminSelectOpt: Yup.mixed()
        .nullable()
        .test(
          "admin-select-validate",
          "Admin selection is required.",
          function (value) {
            const { roleSelect } = this.parent;
            if (
              userData?.role === "Super Admin" &&
              roleSelect !== "Admin" &&
              !value
            ) {
              return false; // Will trigger the error message
            }
            return true;
          }
        ),
    }),

    onSubmit: (values, { resetForm }) => {
      // updateUsers(values);
      const submitValues = {
        ...values,
        departmentSelect:
          values.departmentSelect === ""
            ? null
            : Number(values.departmentSelect),
      };
      updateUsers(submitValues);
    },
  });

  let id = validation?.values?.id;

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const allValues = allOptions.map((option) => option.value);
    const selectedValues = selectedOptions.map((option) => option.value);

    validation.setFieldValue(
      "asset_url_management",
      selectedOptions.map((item) => item.value)
    );

    setSelectAllWebsites(selectedValues.length === allValues.length);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === "selectAllWebsites") {
      if (checked) {
        validation.setFieldValue(
          "asset_url_management",
          allOptions.map((item) => item.value)
        );
      } else {
        validation.setFieldValue("asset_url_management", []);
      }
      setSelectAllWebsites(checked);
      setSelectedOptions(checked ? allOptions : []);
    } else {
      setCheckboxes({ ...checkboxes, [name]: checked });
    }
  };

  const getInternalUsers = useCallback(
    async (searchParams) => {
      try {
        let params = {
          searchParams: searchParams || " ",
          departmentNumber: departmentStatus || " ",
        };

        const response = await getInternalUsersData(params);

        getInternalUsersRes({
          response,
          selectedDepartment,
          setData,
          navigate,
        });
      } catch (error) {
        ToastMessage.Error(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, selectedDepartment, token, departmentStatus]
  );

  useEffect(() => {
    const debouncedFetchData = _.debounce(() => {
      getInternalUsers(query);
    }, 500);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [getInternalUsers, query, departmentStatus]);

  const getUserType = useCallback(async () => {
    try {
      let { data, status, message } = await axios.get(API_ROUTES.GET_ROLE);
      const decryptedData = common.decrypt(data);

      if (status === "success") setRoleType(decryptedData);
      if (status === "fail") ToastMessage.Error(message);
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  useEffect(() => {
    getUserType();
  }, [getUserType]);

  let security = [];

  if (checkboxes.web_security_read === true) {
    security.push("read");
  }

  if (checkboxes.web_security_execute === true) {
    security.push("execute");
  }

  let notes = [];

  if (checkboxes.notes_read === true) {
    notes.push("read");
  }

  if (checkboxes.notes_execute === true) {
    notes.push("execute");
  }

  const getWebsiteData = useCallback(async (searchParams) => {
    try {
      const { status, data } = await axios.get(
        `websiteCategories/get-category`
      );

      const responseData = common.decrypt(data);
      if (status === "success") setWebsiteCategory(responseData);
    } catch (error) {
      console.error("Error fetching website data:", error.message);
    }
  }, []);

  useEffect(() => {
    getWebsiteData();
  }, [getWebsiteData]);

  const assetsIdArray = selectedOptions?.map((item) => item.value);

  let assetUrlManagement = {
    assetsId: assetsIdArray,
    web_security: security,
    notes: notes,
  };

  const updateData = async (row) => {
    setSelectedCountryOption({ name: row.country !== null ? row.country : "" });
    setDepartmentSelectOpt(
      departmentOptions.find((option) => option.value === row.department_id)
    );

    setRoleSelectOpt(roleOptions.find((option) => option.value === row.role));
    setStatusSelectOpt(options.find((option) => option.value === row.status));

    let allValues = await fetchAssetsByDepartment(
      { value: row.department_id },
      row.role
    );

    const assetManagementData = JSON.parse(row.asset_url_management);
    const { notes, web_security } = assetManagementData;

    let selectedValues = allValues?.filter((option) =>
      assetManagementData.assetsId.includes(option.value)
    );

    setSelectedOptions(selectedValues);

    setCheckboxes({
      web_security_read: web_security.includes("read"),
      web_security_execute: web_security.includes("execute"),
      notes_read: notes.includes("read"),
      notes_execute: notes.includes("execute"),
    });

    validation.setValues({
      id: row.id,
      fullName: row.user_name,
      roleSelect: row.role,
      departmentSelect: row.department_id || 0,
      email: row.email,
      hiddenPassword: row.password,
      password: "",

      phone: row.contact_no || "",
      statusOption: row.status,
      departmentImage: row.user_image,
      adminSelectOpt: row.parent_id,
      packageSelectOpt: row.package_id,
      selectedPriceType: row.billing_cycle,
      country: row.country,
      isUserAvailableInThisPackage: row.isUserAvailableInThisPackage || false,
    });
    setShow(true);
  };

  const updateUsers = async (values) => {
    setPostIsLoading(true);
    try {
      const { url, reqData } = updateInternalUsersReq(
        values,
        id,
        assetUrlManagement,
        hiddenPassword
      );

      let result = await fetch(url, reqData);
      let response = await result.json();

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.status === "success") {
        response.message.message === "User updated successfully"
          ? socket.emit("updateCoreUser")
          : socket.emit("addCoreUser");
      }

      updateInternalUsersRes({
        response,
        navigate,
        clearState,
        getInternalUsers,
        setShow,
        ToastMessage,
        id,
      });

      setTimeout(() => {
        setAllOptions([]);
        setHiddenPassword("");
        validation.resetForm();
        setSelectedImage(null);
        setRoleSelectOpt(null);
        setSelectedCountryOption(null);
        setAdminSelectOpt(null);
        setPackageSelectOpt(null);
        setDepartmentSelectOpt(null);
        clearState();
        setStatusSelectOpt({ value: "Active", label: "Active" });
      }, 2000);
      // fetchUserPackageLimit(user_id);
      // fetchPackageServiceDataByUserId(user_id);
      setPostIsLoading(false);
    } catch (error) {
      console.error("error", error.message);
      setPostIsLoading(false);
    } finally {
      // Ensure postIsLoading is set to false after the request
      setPostIsLoading(false);
    }
  };

  const deleteDepartment = async (id, userId) => {
    try {
      const { url, reqData } = DeleteDepartment(id, userId);

      let result = await fetch(url, reqData);
      let response = await result.json();

      if (response.message === "success") socket.emit("deleteCoreUser");

      DeleteDepartmentRes({ response, navigate, getInternalUsers });
    } catch (error) {
      console.error(error.message);
    }
  };

  const updateStatus = async (id, newStatus, email) => {
    try {
      const { url, reqData } = changeInternalUserStatusReq(
        id,
        newStatus,
        email
      );

      let result = await fetch(url, reqData);
      let response = await result.json();

      changeInternalUserStatusRes({
        response,
        navigate,
        getInternalUsers,
        ToastMessage,
      });
    } catch (error) {
      return null;
    }
  };

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteDepartment(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "User has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "User is safe :)",
            "error"
          );
        }
      });
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => (
        <>
          <Box className="d-flex align-items-center">
            <Box className="flex-shrink-0 me-2">
              {row.user_image != null ? (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${row.user_image}`}
                  height="32px"
                  width="32px"
                  alt=""
                  style={{ borderRadius: "100px", background: "#f1f1f1" }}
                />
              ) : (
                <ProfileImage username={row.user_name} />
              )}
            </Box>
            <Box className="flex-grow-1">
              {<strong>{row.user_name}</strong>}
            </Box>
          </Box>
        </>
      ),
      selector: (row) => row.user_name,
      sortable: true,
      grow: 1.5,
      minWidth: "170px",
    },
    {
      name: "Role",
      selector: (row) => row.role || "-",
      minWidth: "150px",
    },
    {
      name: "Department",
      cell: (row) => {
        const departmentLabel = websiteCategory.find(
          (category) => category.id === row.department_id
        )?.category;
        return departmentLabel || "-";
      },
      grow: 1.5,
      minWidth: "150px",
    },
    {
      name: "E-mail ID",
      cell: (row) => (
        <>
          <a href={`mailto:${row.email}`} style={{ marginRight: "8px" }}>
            {row.email || "-"}
          </a>
        </>
      ),
      sortable: true,
      grow: 1.5,
      minWidth: "200px",
    },
    {
      name: "Phone No.",
      cell: (row) => (
        <>
          <a href={`tel:${row.contact_no}`} style={{ marginRight: "8px" }}>
            {row.contact_no || "-"}
          </a>
        </>
      ),
      sortable: true,
      grow: 1.5,
      minWidth: "120px",
    },
    ...(userData?.role === "Super Admin"
      ? [
          {
            name: "Package Detail",
            cell: (row) => (
              <>
                {row.Package?.title ? (
                  <>
                    <span style={{ marginRight: "8px" }}>
                      {row.Package?.title || "-"}
                    </span>
                    {row.billing_cycle && (
                      <span style={{ fontStyle: "italic", color: "#777" }}>
                        ({row.billing_cycle})
                      </span>
                    )}
                  </>
                ) : (
                  "-"
                )}{" "}
              </>
            ),
            sortable: true,
            grow: 1.5,
            minWidth: "200px",
          },
        ]
      : []),
    {
      name: "Status",
      cell: (row) => {
        return row.status.includes("In-Active") ? (
          <>
            <span
              className={`badge bg-soft-danger ${
                row.role !== "Super Admin" && row.id !== user_id
                  ? "cursor-pointer"
                  : ""
              }`}
              style={
                row.role === "Super Admin" || row.id === user_id
                  ? { opacity: 0.6 }
                  : {}
              }
              onClick={
                row.role !== "Super Admin" && row.id !== user_id
                  ? () => {
                      updateStatus(row.id, "In-Active", row.email);
                    }
                  : undefined
              }
            >
              In-Active
            </span>
          </>
        ) : (
          <>
            <span
              className={`badge bg-soft-success ${
                row.role !== "Super Admin" && row.id !== user_id
                  ? "cursor-pointer"
                  : ""
              }`}
              style={
                row.role === "Super Admin" || row.id === user_id
                  ? { opacity: 0.6 }
                  : {}
              }
              onClick={
                row.role !== "Super Admin" && row.id !== user_id
                  ? () => updateStatus(row.id, "Active", row.email)
                  : undefined
              }
            >
              Active
            </span>
          </>
        );
      },
      center: true,
      minWidth: "130px",
    },
    {
      name: (
        <span className="text-center">
          {hasPermission &&
          (hasPermission?.canModify != null || hasPermission?.canDelete != null)
            ? "Actions"
            : ""}
        </span>
      ),
      cell: (row) => (
        <span className="table-action">
          {userData?.roles?.isSuperAdmin === "Y" &&
            row.roles.chr_is_admin === "Y" &&
            row.department_id == "0" && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-edit-${row.id}`}>
                      Account Setting
                    </Tooltip>
                  }
                >
                  <IconButton
                    onClick={() =>
                      navigate("/user-services-settings", {
                        state: { rowData: row },
                      })
                    }
                    size="small"
                    color="primary"
                  >
                    <ManageAccountsIcon />
                  </IconButton>
                </OverlayTrigger>
              </>
            )}
          {hasPermission && hasPermission?.canModify != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-edit-${row.id}`}>
                    {row?.role === "Super Admin"
                      ? common.ISSUPERADMINMESSAGE
                      : "Edit"}
                    {/* Edit */}
                  </Tooltip>
                }
              >
                <span
                  id="create-btn"
                  // className="cursor-pointer"
                  className={`${
                    row?.role !== "Super Admin" ? "cursor-pointer" : ""
                  }`}
                  style={row?.role === "Super Admin" ? { opacity: 0.6 } : {}}
                  onClick={() =>
                    row?.role !== "Super Admin"
                      ? updateData(row, assetUrlManagement)
                      : undefined
                  }
                >
                  <EditOutlinedIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
          {hasPermission && hasPermission?.canDelete != null ? (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-delete-${row.id}`}>
                    {row.isUserAvailableInThisPackage
                      ? common.ISADMINASSIGNE
                      : "Delete"}
                  </Tooltip>
                }
              >
                <span
                  className={`${
                    !row.isUserAvailableInThisPackage && row.id !== user_id
                      ? "cursor-pointer"
                      : ""
                  }`}
                  style={
                    row.id === user_id || row.isUserAvailableInThisPackage
                      ? { opacity: 0.6 }
                      : {}
                  }
                  onClick={() =>
                    !row.isUserAvailableInThisPackage && row.id !== user_id
                      ? handleDeleteAlert(row.id)
                      : undefined
                  }
                >
                  <DeleteIcon />
                </span>
              </OverlayTrigger>
            </>
          ) : (
            ""
          )}
        </span>
      ),
      minWidth: "120px",
      center: true,
    },
  ];

  const options = [
    { value: "Active", label: "Active" },
    { value: "In-Active", label: "In-Active" },
  ];

  const roleOptions = roleType.map((d) => ({
    value: d.name,
    label: d.name,
  }));

  let adminOptions = [];

  const departmentOptions = websiteCategory.map((d) => ({
    value: d.id,
    label: d.category,
  }));

  const fetchAssetsByDepartment = async (
    departmentSelectOpt,
    role = null,
    dontResetFlag = true
  ) => {
    try {
      let departmentId =
        (departmentSelectOpt && departmentSelectOpt.value) || null;
      const { data } = await axios.post(
        "online-assets/online-assets-department-list",
        {
          department_id: departmentId,
          role,
        }
      );

      const decrypedData = common.decrypt(data);

      const assetIds = decrypedData.map((item) => ({
        label: item.website_url,
        value: item.id,
      }));
      setAllOptions(assetIds);
      if (!dontResetFlag) return assetIds;

      setCheckboxes({
        web_security_read: false,
        web_security_execute: false,
        notes_read: false,
        notes_execute: false,
      });
      setSelectedOptions([]);

      return assetIds;
    } catch (error) {
      console.error("Error fetching assets:", error.message);
    }
  };

  const fetchAllAdmins = () => {
    const allAdminsData = data.filter((item) => item.role === "Admin");
    adminOptions = allAdminsData.map((item) => ({
      value: item.id,
      label: item.user_name,
    }));
  };

  if (
    userData?.role === "Super Admin" &&
    roleSelectOpt?.value !== "Admin" &&
    roleSelectOpt !== null
  )
    fetchAllAdmins();

  const handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      const validSize = file.size <= convertMBToBytes(DEFAULT_IMAGE_SIZE);

      if (!allowedFormats.includes(file.type)) {
        validation.setFieldError(
          "departmentImage",
          "Invalid file format. Only JPEG, JPG, PNG, and SVG files are allowed."
        );
        event.target.value = null;
      } else if (!validSize) {
        validation.setFieldError(
          "departmentImage",
          `Failed to upload an image. The image maximum size is ${DEFAULT_IMAGE_SIZE} MB.`
        );
        event.target.value = null;
      } else {
        validation.setFieldError("departmentImage", "");
        validation.setFieldValue("departmentImage", file);
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
      }
    }
  };

  const clearState = () => {
    validation.resetForm();
    validation.setFieldValue("departmentImage", null);

    setCheckboxes({
      web_security_read: false,
      web_security_execute: false,
      notes_read: false,
      notes_execute: false,
    });
    setSelectedOptions([]);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedOptions([]);
    setAllOptions([]);
    setSelectedImage(null);
    setRoleSelectOpt(null);
    setSelectedCountryOption(null);
    setDepartmentSelectOpt(null);
    setPackageSelectOpt(null);
    setStatusSelectOpt({ value: "Active", label: "Active" });
    setTimeout(() => {
      clearState();
    }, 500);
  };

  const handleReset = () => {
    // setIsLoading(true);
    setSelectedDepartment(null);
    setDepartmentStatus("");
    setQuery("");
  };

  // const fetchPackageServiceDataByUserId = async (user_id) => {
  //   try {
  //     const response = await getPackagesServices(user_id);
  //     const responseData = common.decrypt(response.data);
  //     setMyPackage(responseData);
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  // const fetchUserPackageLimit = async (user_id) => {
  //   try {
  //     const response = await getUserPackageLimit(user_id);
  //     const responseData = common.decrypt(response.data);
  //     setMyUsersLimit(responseData);
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // };

  const fetchAllPackageList = async () => {
    try {
      const response = await getAllPackageList();
      const responseData = common.decrypt(response.data);
      setAllPackage(responseData);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchAllPackageList();
  }, []);

  // useEffect(() => {
  //   fetchUserPackageLimit(user_id);
  //   fetchPackageServiceDataByUserId(user_id);
  // }, [user_id]);

  // const usersLimitsData = myPackage[0]?.packageServiceData?.find(
  //   (service) => service.slug === "users_limits"
  // );

  // const usersLimitsValue = usersLimitsData ? usersLimitsData.value : null;

  // const isLimitReached =
  //   userData?.role !== "Super Admin" &&
  //   usersLimitsValue !== null &&
  //   myUsersLimit?.users_limits >= usersLimitsValue;

  // const isUserStatusCheck =
  //   userData?.role !== "Super Admin" && !myUsersLimit?.isUserStatusCheck;
  // const isPackageExpired =
  //   userData?.role !== "Super Admin" && myUsersLimit?.isPackageExpired;
  // const isPackageInactive =
  //   userData?.role !== "Super Admin" && myUsersLimit?.packageInactive;

  // const tooltipMessage = isPackageInactive
  //   ? common.PACKAGEINACTIVE
  //   : isUserStatusCheck
  //   ? common.ADMINMESSAGE
  //   : isPackageExpired
  //   ? common.USERPACKAGEEXPIREMESSAGE
  //   : isLimitReached
  //   ? common.USERLIMITMESSAGE
  //   : "";

  // const tooltip = (
  //   <Tooltip id="button-tooltip" style={{ zIndex: 9999 }}>
  //     {tooltipMessage}
  //   </Tooltip>
  // );

  // Fetch services and user preferences
  const fetchUserAccountData = async (id) => {
    try {
      const response = await getCurrentUsageService(id);
      const assetResponse = response?.find(
        (item) => item.slug === "users_limits"
      );
      const isUsersEnabled = assetResponse?.is_enabled ? true : false;
      setIsUsersEnabled(isUsersEnabled);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchUserAccountData(user_id);
  }, [user_id]);

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                value={query}
                className="form-control"
                placeholder="Search by name & email..."
                onChange={(e) => {
                  setIsLoading(true);
                  setQuery(e.target.value);
                }}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            {userData?.role === "Super Admin" ||
              (userIsAdmin === "Y" && userDepartment === 0 && (
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    width: "220px",
                    minWidth: "190px",
                  }}
                >
                  <Autocomplete
                    options={departmentOptions}
                    getOptionLabel={(option) => option.label}
                    value={
                      departmentOptions.find(
                        (option) => option.value === departmentStatus
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      setDepartmentStatus(newValue?.value || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Department" />
                    )}
                    fullWidth
                    sx={AutoDropdownStyle}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    disableClearable
                    renderOption={(props, option) => {
                      const { key, ...otherProps } = props;
                      return (
                        <li
                          key={key}
                          {...otherProps}
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {option.label}
                        </li>
                      );
                    }}
                  />
                </FormControl>
              ))}
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>
          {hasPermission && hasPermission?.canCreate != null && (
            <>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip}`}>
                    {userData?.roles?.isSuperAdmin !== "Y" && !isUsersEnabled
                      ? "You have not enabled the Users. Go to Account services to enable."
                      : "Add User"}
                  </Tooltip>
                }
              >
                <span>
                  <button
                    type="button"
                    className="btn btn-primary view-btn"
                    id="create-btn"
                    onClick={handleShow}
                    style={{ minWidth: "162px" }}
                    disabled={
                      userData?.roles?.isSuperAdmin !== "Y" && !isUsersEnabled
                    }
                  >
                    <i className="ri-user-add-line icon-md me-2 fs-15 align-middle"></i>
                    Add User
                  </button>
                </span>
              </OverlayTrigger>
            </>
          )}
        </Box>
        <Card.Body>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              fixedHeight="500px"
              columns={columns}
              data={data}
              pagination
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>

      <InternalUsersModal
        show={show}
        id={id}
        validation={validation}
        fetchAssetsByDepartment={fetchAssetsByDepartment}
        handleClose={handleClose}
        statusSelectOpt={statusSelectOpt}
        setStatusSelectOpt={setStatusSelectOpt}
        options={options}
        checkboxes={checkboxes}
        handleCheckboxChange={handleCheckboxChange}
        selectedOptions={selectedOptions}
        handleSelectChange={handleSelectChange}
        allOptions={allOptions}
        selectAllWebsites={selectAllWebsites}
        handleChangeImage={handleChangeImage}
        roleOptions={roleOptions}
        roleSelectOpt={roleSelectOpt}
        adminOptions={adminOptions}
        setAdminSelectOpt={setAdminSelectOpt}
        adminSelectOpt={adminSelectOpt}
        setRoleSelectOpt={setRoleSelectOpt}
        departmentOptions={departmentOptions}
        noSelectedImage={noSelectedImage}
        selectedImage={selectedImage}
        departmentSelectOpt={departmentSelectOpt}
        setDepartmentSelectOpt={setDepartmentSelectOpt}
        setSelectedOptions={setSelectedOptions}
        setSelectAllWebsites={setSelectAllWebsites}
        postIsLoading={postIsLoading}
        setPostIsLoading={setPostIsLoading}
        userData={userData}
        allPackage={allPackage}
        setAllPackage={setAllPackage}
        packageSelectOpt={packageSelectOpt}
        setPackageSelectOpt={setPackageSelectOpt}
        setCountryViceNumberLength={setCountryViceNumberLength}
        selectedCountryOption={selectedCountryOption}
        selectedPriceType={selectedPriceType}
        setSelectedPriceType={setSelectedPriceType}
        DEFAULT_IMAGE_SIZE={DEFAULT_IMAGE_SIZE}
      />
    </>
  );
};

export default InternalUsers;
